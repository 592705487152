import React, { ReactNode } from 'react';
import reactComponentSlots from '../../utils/reactComponentSlots';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SvgChevronRight from '../../Icons/ChevronRight';

interface PosterProps {
    //label?: string;
    title: string;
    text?: string;
    href?: string;
    backgroundImage?: string;
    slot?: string;
    children?: ReactNode;
    size: 'small' | 'large';
    flow: 'column' | 'row';
    label?: string;
    overlay?: boolean;
}

const Poster = (props: PosterProps) => {
    const slots = reactComponentSlots(props);

    const { title, text, backgroundImage, size, href, flow, label, overlay } = props;

    // const posterClass = classNames({
    //     [`poster`]: true,
    //     [`poster--label`]: label,
    // });

    const backgroundImageClass = classNames({
        [`poster__background`]: true,
    });

    const mainClass = classNames({
        [`poster__main`]: true,
        [`poster__main--${size}`]: true,
        [`poster__main--${flow}`]: true,
    });

    const contentClass = classNames({
        [`poster__content`]: true,
        [`poster__content--${flow}`]: true,
        [`poster__content--overlay`]: overlay,
    });

    const titleClass = classNames({
        [`poster__title`]: true,
        [`poster__title--${size}`]: true,
        [`poster__title--overlay`]: overlay,
    });

    const textClass = classNames({
        [`poster__text`]: true,
        [`poster__text--${size}`]: true,
    });

    const headlineClass = classNames({
        [`poster__headline`]: true,
    });

    const actionsClass = classNames({
        [`poster__actions`]: true,
        [`poster__actions--overlay`]: overlay,
    });

    const renderContents = () => {
        return (
            <div className={contentClass}>
                <div className="poster__header">
                    <h3 className={titleClass}>{label}</h3>
                </div>
                <div className="poster__body">
                    <Link to={{ pathname: href }} className={headlineClass}>
                        {title}
                    </Link>
                    {/* {text && (
                        <p className={textClass}>
                            <Link to={{ pathname: href }}>{text}</Link>
                        </p>
                    )} */}
                    {/* <Link
                        className="poster__link button button--light button--outline button--round"
                        to={{ pathname: href }}
                    >
                        <SvgChevronRight width={8} height={15} />
                    </Link> */}
                </div>
                <div className={actionsClass}>{slots['actions']}</div>
            </div>
        );
    };

    if (slots['actions'] || !href) {
        return (
            <div className="poster">
                <div className={mainClass}>{renderContents()}</div>
                <div className={backgroundImageClass} style={{ backgroundImage: `url(${backgroundImage})` }} />
            </div>
        );
    }

    return (
        <div className="poster">
            <Link to={href} className={mainClass}>
                {renderContents()}
            </Link>
            <div className={backgroundImageClass} style={{ backgroundImage: `url(${backgroundImage})` }} />
        </div>
    );
};

Poster.defaultProps = {
    size: 'large',
    flow: 'row',
    overlay: true,
};

export default Poster;
