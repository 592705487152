import { apiService } from '../../../constants/apiBase';
import { PLAYBOOK_CORE_SUCCESS, PLAYBOOK_CORE_NAV_SUCCESS } from './types';

export const fetchPlaybookCore = () => async dispatch => {
    try {
        const { data } = await apiService.get('core_sections/all');

        dispatch({
            type: PLAYBOOK_CORE_SUCCESS,
            payload: data,
        });

        console.log('fetched play book');
    } catch (e) {
        console.log(e);
    }
};

export const fetchPlaybookCoreNav = () => async dispatch => {
    try {
        const { data } = await apiService.get('core_sections/menu');

        dispatch({
            type: PLAYBOOK_CORE_NAV_SUCCESS,
            payload: data,
        });

        console.log('fetched play book nav');
    } catch (e) {
        console.log(e);
    }
};
