//import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import SvgChevronLeft from '../../Icons/ChevronLeft';
import SvgChevronRight from '../../Icons/ChevronRight';

interface Props {
    visible?: boolean;
    items: {
        id: number;
        type: string;
        thumb: string;
        url: string;
        category: string;
        title: string;
    }[];
    startIndex: number;
    onClose?: any;
}

interface State {
    currentIndex: number;
    maxHeight: number;
}

const target = document.body;

// const animationClasses = {
//     enter: 'lightbox-gallery__container--enter',
//     enterActive: 'lightbox-gallery__container--enter-active',
//     leave: 'lightbox-gallery__container--leave',
//     leaveActive: 'lightbox-gallery__container--leave-active',
// };

class LightboxGallery extends Component<Props, State> {
    static defaultProps = {};

    state: Readonly<State> = {
        currentIndex: 0,
        maxHeight: 0,
    };

    el = document.createElement('div') as HTMLDivElement;

    componentDidMount() {
        target.appendChild(this.el);
        this.el.classList.add('lightbox-gallery');

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps: Props) {
        const { startIndex, visible } = this.props;

        if (startIndex !== prevProps.startIndex || visible !== prevProps.visible) {
            this.setState({ currentIndex: startIndex });

            document.body.classList.toggle('lightbox-gallery-active', this.props.visible);
        }
    }

    componentWillUnmount() {
        target.removeChild(this.el);

        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    // TODO: Add iframe
    // TODO: Add title
    // TODO: left/right with arrow keys
    // TODO: swipe left/right for mobile

    updateWindowDimensions = () => {
        this.setState({ maxHeight: window.innerHeight });
    };

    handleClose = () => {
        this.props.onClose();
    };

    prevImage = () => {
        if (this.state.currentIndex > 0) {
            this.setState(state => {
                return {
                    currentIndex: state.currentIndex - 1,
                };
            });
        }
    };

    nextImage = () => {
        if (this.state.currentIndex < this.props.items.length - 1) {
            this.setState(state => {
                return {
                    currentIndex: state.currentIndex + 1,
                };
            });
        }
    };

    render() {
        const { items } = this.props;
        const { maxHeight } = this.state;
        const currentItem = items[this.state.currentIndex];

        return ReactDOM.createPortal(
            this.props.visible && currentItem ? (
                <div className="lightbox-gallery__container">
                    <div className="lightbox-gallery__inner">
                        <button className="lightbox-gallery__button lightbox-gallery__close" onClick={this.handleClose}>
                            &times;
                        </button>
                        <div
                            className="lightbox-gallery__content"
                            style={{ height: currentItem.type === 'iframe' ? maxHeight : '100%' }}
                        >
                            {currentItem.type === 'image' ? (
                                <div className="lightbox-gallery__image">
                                    <figure>
                                        <img src={currentItem.url} style={{ maxHeight }} alt={currentItem.title} />
                                        <figcaption>{currentItem.title}</figcaption>
                                    </figure>
                                </div>
                            ) : (
                                <div className="lightbox-gallery__iframe">
                                    <div className="iframe">
                                        <iframe
                                            src={currentItem.url}
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen
                                            title="Iframe"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="lightbox-gallery__nav">
                            <button
                                className="lightbox-gallery__button lightbox-gallery__prev"
                                onClick={this.prevImage}
                            >
                                <SvgChevronLeft width={12} height={22} />
                            </button>
                            <button
                                className="lightbox-gallery__button lightbox-gallery__next"
                                onClick={this.nextImage}
                            >
                                <SvgChevronRight width={12} height={22} />
                            </button>
                        </div>
                    </div>
                </div>
            ) : null,
            this.el,
        );
    }
}

export default LightboxGallery;

// function firstChild(props: any) {
//     const childrenArray = React.Children.toArray(props.children);
//     return childrenArray[0] || null;
// }

// <ReactCSSTransitionGroup
//     transitionName={{
//         enter: 'lightbox-gallery__container--enter',
//         enterActive: 'lightbox-gallery__container--enter-active',
//         leave: 'lightbox-gallery__container--leave',
//         leaveActive: 'lightbox-gallery__container--leave-active',
//     }}
//     component="div"
//     transitionEnterTimeout={300}
//     transitionLeaveTimeout={300}
// >

//</ReactCSSTransitionGroup>,
