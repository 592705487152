import { LOADER_SHOW, LOADER_HIDE } from '../actions/types';

const initialState = {
    visible: false,
    text: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADER_SHOW: {
            return {
                visible: true,
                text: action.text,
            };
        }

        case LOADER_HIDE: {
            return initialState;
        }

        default:
            return state;
    }
};
