import React, { Component } from 'react';
import classNames from 'classnames';
import { Pagination } from '../../components';

interface ListProps {
    rowGap?: 'small' | 'medium' | 'large';
    children: any[];
    pageLimit: number;
    grid?: boolean;
}

interface ListState {
    currentPage: number;
}

/**
 * Available props:
 * - pageLimit (paginates child components)
 * - rowGap (small, medium, large)
 *
 * ```
 * // e.g.
 * <List pageLimit={10} rowGap="small" />
 * ```
 */

class List extends Component<ListProps, ListState> {
    static defaultProps = {
        pageLimit: null,
        children: [],
    };

    state = {
        currentPage: 1,
    };

    componentDidUpdate(prevProps: ListProps) {
        if (this.props.children !== prevProps.children) {
            this.setState({ currentPage: 1 });
        }
    }

    handlePageChange = (currentPage: number) => {
        this.setState({ currentPage });
    };

    currentPageData() {
        const { children } = this.props;

        if (!this.props.pageLimit) {
            return children;
        }
        const { currentPage } = this.state;
        const { pageLimit } = this.props;
        const pageOffset = (currentPage - 1) * pageLimit;
        return children.slice(pageOffset, pageOffset + pageLimit);
    }

    render() {
        const { rowGap, pageLimit, grid } = this.props;
        const { currentPage } = this.state;

        const listClass = classNames({
            [`list`]: true,
            [`list--grid`]: grid,
        });

        const listItemClass = classNames({
            [`list__item`]: true,
            [`list__item--grid`]: grid,
            [`list__item--row-gap-sm`]: rowGap === 'small',
            [`list__item--row-gap-md`]: rowGap === 'medium',
            [`list__item--row-gap-lg`]: rowGap === 'large',
        });

        return (
            <div>
                <div className={listClass}>
                    {this.currentPageData().map((child, i) => (
                        <div className={listItemClass} key={i}>
                            {child}
                        </div>
                    ))}
                </div>
                {pageLimit && (
                    <div className="list__pagination">
                        <Pagination
                            totalItems={this.props.children.length}
                            pageLimit={pageLimit}
                            currentPage={currentPage}
                            onPageChange={this.handlePageChange}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default List;
