import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import AssetDownloads from './containers/AssetDownloads/AssetDownloads';
import BestPractice from './containers/BestPractice/BestPractice';
import BestPracticeSingular from './containers/BestPractice/BestPracticeSingular';
import CapabilityModules from './containers/CapabilityModules/CapabilityModules';
import CovidArchive from './containers/Covid/Covid';
import CovidSingular from './containers/Covid/CovidSingular';
import GlobalLayout from './layouts/GlobalLayout';
import GlobalNavbar from './containers/GlobalNavbar';
import Loader from './containers/@Shared/Loader/Loader';
import News from './containers/News/News';
import NewsSingular from './containers/News/NewsSingular';
import Overview from './containers/Overview/Overview';
import Playbook from './containers/Playbook/Playbook';
import PlaybookPortal from './containers/PlaybookPortal';
import SwpToolkits from './containers/SwpToolkits/SwpToolkits';
import ShopperXP from './containers/ShopperXP/ShopperXP';

import { fetchAppContent } from './actions/app_actions';
import Welcome from './containers/Welcome/Welcome';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Helvetica Neue LT Std',
        body2: {
            fontSize: '0.75rem',
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 300,
            marginBottom: '0.625rem',
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: 600,
            marginBottom: '0.625rem',
        },
    },
    palette: {
        primary: {
            main: '#F7D1C4',
        },
        secondary: {
            main: '#262626',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1400,
            xl: 1920,
        },
    },
    overrides: {
        MuiContainer: {
            maxWidthLg: {
                maxWidth: 1400,
            },
        },
        MuiButton: {
            contained: {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'black',
            },
            outlined: {
                borderColor: 'black',
            },
        },
        MuiCard: {
            root: {
                backgroundColor: '#F7D1C4',
                fontSize: '1rem',
            },
        },
        MuiCardHeader: {
            root: {
                paddingBottom: 5,
            },
            title: {
                fontSize: '1.25rem',
                fontWeight: 300,
            },
        },
        MuiCardContent: {
            root: {
                paddingTop: 5,
            },
        },
        MuiCheckbox: {
            root: {
                padding: 5,
                marginLeft: 5,
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'white!important',
            },
        },
        MuiTextField: {
            root: {},
        },
        MuiFormLabel: {
            root: {},
        },
    },
});

interface AppRouterProps {
    fetchAppContent: any;
}

class AppRouter extends Component<AppRouterProps> {
    state = {
        modalIsOpen: false,
    };

    componentDidMount() {
        this.props.fetchAppContent();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <GlobalLayout fixedHeader>
                    <GlobalNavbar slot="header" />
                    <Route path="/" exact component={PlaybookPortal} />
                    <Route path="/overview/" exact component={Overview} />
                    <Route path="/playbook/:path/" component={Playbook} />
                    <Route path="/covid19/" exact component={CovidArchive} />
                    <Route path="/covid19/:id" component={CovidSingular} />
                    <Route path="/research-best-practice/" exact component={BestPractice} />
                    <Route path="/research-best-practice/:path/" component={BestPracticeSingular} />
                    <Route path="/news-documents/" exact component={News} />
                    <Route path="/news-documents/:path/" component={NewsSingular} />
                    <Route path="/news/" exact component={News} />
                    <Route path="/news/:path/" component={NewsSingular} />
                    <Route path="/capability-modules/" exact component={CapabilityModules} />
                    <Route path="/swp-toolkits/" exact component={SwpToolkits} />
                    <Route path="/asset-downloads/" exact component={AssetDownloads} />
                    <Route path="/shopper-xp/" exact component={ShopperXP} />
                </GlobalLayout>

                <Loader />
                <Welcome />
            </ThemeProvider>
        );
    }
}

export default connect(null, { fetchAppContent })(AppRouter);
