import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface NavGridProps {
    slot?: string;
    children: ReactNode;
    flow: 'row' | 'column';
    expand?: boolean;
}

const NavGrid = (props: NavGridProps) => {
    const { children } = props;

    const navGridClass = classNames({
        [`nav-grid`]: true,
    });

    return (
        <div className={navGridClass}>
            {React.Children.toArray(children).map((child, i) => (
                <div className="nav-grid__item" key={i}>
                    {child}
                </div>
            ))}
        </div>
    );
};

NavGrid.defaultProps = {
    flow: 'column',
};

export default NavGrid;
