import React, { ReactNode, Component } from 'react';
import reactComponentSlots from '../../utils/reactComponentSlots';
import classNames from 'classnames';

interface GlobalLayoutProps {
    children?: ReactNode;
    fixedHeader?: boolean;
}

interface GlobalLayoutState {
    headerHeight: number;
}

class GlobalLayout extends Component<GlobalLayoutProps, GlobalLayoutState> {
    state = {
        headerHeight: 0,
    };

    header = React.createRef<HTMLDivElement>();

    updateHeaderPadding = () => {
        if (this.header !== null && this.header.current !== null && this.props.fixedHeader) {
            this.setState({ headerHeight: this.header.current.clientHeight });
        }
    };

    componentDidMount() {
        this.updateHeaderPadding();
        window.addEventListener('resize', this.updateHeaderPadding);
    }

    render() {
        const slots = reactComponentSlots(this.props);

        const headerClass = classNames({
            [`global__header`]: true,
            [`global__header--fixed`]: this.props.fixedHeader,
        });

        return (
            <div className="global" style={{ paddingTop: this.state.headerHeight }}>
                <div className={headerClass} ref={this.header}>
                    {slots['header']}
                </div>
                {slots['default']}
                <div className="global__footer">{slots['footer']}</div>
            </div>
        );
    }
}

export default GlobalLayout;
