import * as React from 'react';

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 16.59 15" {...props}>
        <path
            d="M13.28 8.16l-4.99 4.5-4.98-4.5.92-.83 3.42 3.1V0h1.3v10.42l3.41-3.09zm3.3 5.67H0V15h16.59zm0 0"
            fill="currentColor"
        />
    </svg>
);

export default SvgDownload;
