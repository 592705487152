import React, { ReactNode, Component } from 'react';
// import reactComponentSlots from '../../utils/reactComponentSlots';
// import { Image } from '../';

// import appsIcon from '../../assets/icons/icon_apps.svg';
// import playbookIcon from '../../assets/images/logo@2x.png';
// import assetXPIcon from '../../assets/icons/icon_assetxp@2x.png';
// import categoryXPIcon from '../../assets/icons/icon_categoryxp@2x.png';
// // import planogramXPIcon from '../../assets/icons/icon_planogramxp@2x.png';
// import virtualXPIcon from '../../assets/icons/icon_virtualxp@2x.png';
// import RTMIcon from '../../assets/icons/icon_rtm@3x.png';

interface Props {
    title?: string;
    children?: ReactNode;
    slot?: string;
}

interface State {
    isOpen: boolean;
}

class ToolsMenu extends Component<Props, State> {
    state: Readonly<State> = {
        isOpen: false,
    };

    panel = React.createRef<any>();

    togglePanel = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return null;
    }
}

export default ToolsMenu;
