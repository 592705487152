import * as React from 'react';

const SvgSearch = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M23.82 20.88l-6.35-6.35A9.48 9.48 0 1 0 0 9.47a9.48 9.48 0 0 0 14.3 8.13l6.4 6.4 3.12-3.12zM3.52 9.47a5.95 5.95 0 1 1 11.9.02 5.95 5.95 0 0 1-11.9-.02z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSearch;
