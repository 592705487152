import React, { ReactNode } from 'react';
import classNames from 'classnames';
import reactComponentSlots from '../../utils/reactComponentSlots';

interface CardProps {
    title: string;
    subtitle?: string;
    slot?: string;
    children?: ReactNode;
    mode: 'light' | 'dark';
    size: 'small' | 'large';
    theme?: string;
}

const Card = (props: CardProps) => {
    const slots = reactComponentSlots(props);
    const { title, subtitle, mode, size, theme } = props;

    const cardClass = classNames({
        [`card`]: true,
        [`card--${mode}`]: true,
        [`card--${size}`]: true,
        [`card--${size}`]: true,
        [`card--${theme}`]: theme,
    });

    const cardContentClass = classNames({
        [`card__content`]: true,
        [`card__content--${size}`]: true,
    });

    const titleClass = classNames({
        [`card__title`]: true,
        [`card__title--${mode}`]: true,
        [`card__title--${size}`]: true,
    });

    const subtitleClass = classNames({
        [`card__subtitle`]: true,
        [`card__subtitle--${mode}`]: true,
        [`card__subtitle--${size}`]: true,
    });

    return (
        <div className={cardClass}>
            {slots['media'] && <div className="card__media">{slots['media']}</div>}
            <div className={cardContentClass}>
                <div className="card__header">
                    <h2 className={titleClass}>{title}</h2>
                </div>
                {slots['default'] || subtitle ? (
                    <div className="card__body">
                        {subtitle && <p className={subtitleClass}>{subtitle}</p>}
                        {slots['default']}
                    </div>
                ) : null}
                {slots['actions'] && <div className="card__actions">{slots['actions']}</div>}
            </div>
        </div>
    );
};

Card.defaultProps = {
    mode: 'light',
    size: 'large',
};

export default Card;
