import React, { Component } from 'react';
import { connect } from 'react-redux';
import loadjs from 'loadjs';
import { playbookPortalComponentsSelector } from './selectors/playbook_portal_selectors';
import DashboardLayout from '../../layouts/DashboardLayout';
import buildComponents from '../../utils/buildComponents';

declare global {
    interface Window {
        yam: any;
    }
}

interface Props {
    components: any[];
}

class PlaybookPortal extends Component<Props> {
    state = {
        comp: null,
    };

    handleBuild() {
        const { components } = this.props;
        this.setState({ comp: buildComponents(components) });
    }

    componentDidMount() {
        setTimeout(function() {
            loadjs('https://c64.assets-yammer.com/assets/platform_embed.js', {
                success: function() {
                    window.yam.connect.embedFeed({
                        container: '#yammer',
                        network: 'unilever.com',
                        feedType: 'group',
                        feedId: '9185501184',
                    });
                },
                async: true,
            });
        }, 1500);

        this.handleBuild();
    }

    componentDidUpdate(prevProps) {
        if (this.props.components !== prevProps.components) {
            this.handleBuild();
        }
    }

    render() {
        const { comp } = this.state;
        if (!comp) return null;

        return <DashboardLayout>{this.state.comp}</DashboardLayout>;
    }
}

const mapStateToProps = (state: any) => {
    return {
        components: playbookPortalComponentsSelector(state),
    };
};

export default connect(mapStateToProps, null)(PlaybookPortal);
