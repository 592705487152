import PERSIST_REHYDRATE from 'redux-persist/lib/constants';
import { PLAYBOOK_CORE_SUCCESS } from '../actions/types';
//import initialState from '../../../json/playbook-v2.json';

export default (state = [], action) => {
    switch (action.type) {
        case PERSIST_REHYDRATE:
            return action.payload.playbookCore || state;

        case PLAYBOOK_CORE_SUCCESS: {
            return [...action.payload];
        }

        // case RESET: {
        //     return initialState;
        // }

        default:
            return state;
    }
};
