import React, { Component } from 'react';
import { connect } from 'react-redux';
import { swpToolkitsSelector, swpToolkitsCategoriesSelector } from './selectors/swp_toolkits_selectors';
import PageLayout from '../../layouts/PageLayout';
import { Title, Panel, List, ListItem } from '../../components';
import Filters from '../@Shared/Filters/Filters';

interface Props {
    title?: string;
    description?: string;
    posts: any[];
    categories: string[];
}

interface State {
    filteredData: any[];
}

class SwpToolkits extends Component<Props, State> {
    state: Readonly<State> = {
        filteredData: [],
    };

    handleFilteredData: any = (data: any) => {
        this.setState({ filteredData: data });
    };

    render() {
        const { title, description, categories, posts } = this.props;

        return (
            <PageLayout>
                <Title slot="header">{title}</Title>
                <Panel theme="default">
                    <h3>{description}</h3>
                </Panel>
                <Filters slot="sidebar" data={posts} categories={categories} filteredData={this.handleFilteredData} />
                <Panel theme="default">
                    <List rowGap="small">
                        {this.state.filteredData.map(item => (
                            <ListItem
                                key={item.id}
                                title={item.title}
                                subtitle={item.excerpt}
                                href={item.path}
                                external
                                icon="download"
                            >
                                {item.thumb && <img src={item.thumb} slot="media" alt={item.title} />}
                            </ListItem>
                        ))}
                    </List>
                </Panel>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { title, description, posts } = swpToolkitsSelector(state);
    const categories = swpToolkitsCategoriesSelector(state);

    return {
        title,
        description,
        posts,
        categories,
    };
};

export default connect(
    mapStateToProps,
    null,
)(SwpToolkits);
