import _ from 'lodash';

export const covidSelector = state => {
    return state.covid;
};

export const covidPostSelector = (state, path) => {
    const result = state.covid.posts.find(obj => {
        return `/covid19/${obj.id}` === path;
    });

    if (result) {
        return result;
    }

    return [];
};

// export const covidCategoriesSelector = state => {
//     const categories = _.uniq(_.flatMap(state.covid.posts, ({ category }) => category.split(',')));
//     return categories.filter(Boolean);
// };

export const covidFiltersSelector = state => {
    return state.covid.filters;
};
