export const playbookCoreNavSelector = state => {
    return state.playbookCoreNav;
};

export const playBookPostSelector = (state, path) => {
    const result = state.playbookCore.find(obj => {
        return obj.path === path;
    });

    if (result) {
        return result;
    }

    return [];
};
