import * as React from 'react';

const SvgChevronRightCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 36.57 36.57" {...props}>
        <g fill="currentColor">
            <path d="M18.28 36.57a18.28 18.28 0 1118.29-18.29 18.3 18.3 0 01-18.29 18.29zm0-35.33a17.04 17.04 0 1017.05 17.04A17.06 17.06 0 0018.28 1.24z" />
            <path d="M15.99 24.7a.62.62 0 01-.44-1.05l5.62-5.63-5.62-5.62a.62.62 0 11.88-.88l6.05 6.06a.62.62 0 010 .88l-6.05 6.06a.61.61 0 01-.44.18z" />
        </g>
    </svg>
);

export default SvgChevronRightCircle;