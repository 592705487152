import React from 'react';
import classNames from 'classnames';
import { Link } from '../../components';

interface NavbarButtonProps {
    slot?: string;
    href?: string;
}

const NavbarButton = (props: NavbarButtonProps & React.HTMLProps<HTMLButtonElement>) => {
    const { children, href, ...rest } = props;

    const buttonClass = classNames({
        [`navbar-button`]: true,
    });

    if (href) {
        return React.createElement(Link, { href, className: buttonClass, ...rest }, children);
    }
    return React.createElement('button', { className: buttonClass, ...rest }, children);
};

export default NavbarButton;
