import React, { ReactNode } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

interface LinkProps {
    title?: string;
    target?: string;
    alt?: string;
    external?: boolean;
    href: string;
    children?: ReactNode;
    slot?: string;
    className?: any;
}

const Link = (props: LinkProps) => {
    const { children, href, external, ...rest } = props;

    //console.log(href);

    if (external) {
        //return React.createElement('a', { href, ...rest }, children);

        return (
            <ReactGA.OutboundLink {...rest} eventLabel={props.title ? props.title : href} to={href} target="_blank">
                {children}
            </ReactGA.OutboundLink>
        );
    }

    return React.createElement(LinkRouter, { to: href, ...rest }, children);
};

export default Link;
