import React from 'react';
import SvgSpinner from '../../Icons/Spinner';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const animationClasses = {
    enter: 'loader--enter',
    enterActive: 'loader--enter-active',
    leave: 'loader--leave',
    leaveActive: 'loader--leave-active',
    // appear: 'loader--appear',
    // appearActive: 'loader--appear-active',
};

interface Props {
    text?: string;
    fullscreen?: boolean;
    visible?: boolean;
}

function firstChild(props: any) {
    const childrenArray = React.Children.toArray(props.children);
    return childrenArray[0] || null;
}

const Loader = (props: Props) => {
    return (
        <ReactCSSTransitionGroup
            transitionName={animationClasses}
            component={firstChild}
            transitionEnterTimeout={0}
            transitionLeaveTimeout={300}
        >
            {props.visible ? (
                <div className="loader">
                    <div className="loader__inner">
                        <div className="loader__spinner">
                            <SvgSpinner width="100" height="100" />
                        </div>
                        {props.text && <div className="loader__text">{props.text}</div>}
                    </div>
                </div>
            ) : null}
        </ReactCSSTransitionGroup>
    );
};

export default Loader;
