import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import SvgChevronRightCircle from '../../Icons/ChevronRightCircle';

interface NavTabProps {
    slot?: string;
    title: string;
    theme?: string;
    active?: boolean;
    href: string;
}

const NavTab = (props: NavTabProps) => {
    const { title, theme, active, href } = props;

    const navTabClass = classNames({
        [`nav-tab`]: true,
        [`nav-tab--${theme}`]: true,
        [`nav-tab--active`]: active,
    });

    const navTabTitleClass = classNames({
        [`nav-tab__title`]: true,
    });

    const navTabIconClass = classNames({
        [`nav-tab__icon`]: true,
        [`nav-tab__icon--active`]: active,
    });

    return (
        <div className={navTabClass}>
            <Link to={{ pathname: href }}>
                <h3 className={navTabTitleClass}>{title}</h3>
                <SvgChevronRightCircle width={36} height={36} className={navTabIconClass} />
            </Link>
        </div>
    );
};

export default NavTab;
