import React, { ReactNode } from 'react';
import reactComponentSlots from '../../utils/reactComponentSlots';

interface JumbotronProps {
    label?: string;
    title: string;
    backgroundImage?: string;
    actions?: ReactNode;
    slot?: string;
    children?: ReactNode;
    links?: any[];
    theme?: string;
}

const Jumbotron = (props: JumbotronProps) => {
    const slots = reactComponentSlots(props);

    return (
        <div className="jumbotron" style={{ backgroundImage: `url(${props.backgroundImage})` }}>
            {slots['before'] && <div className="jumbotron__before">{slots['before']}</div>}
            <div className="jumbotron__content">
                <h1 className="jumbotron__title">{props.title}</h1>
            </div>
            {slots['actions'] && <div className="jumbotron__actions">{slots['actions']}</div>}
        </div>
    );
};

export default Jumbotron;
