import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface TitleButtonProps {
    slot?: string;
    href?: string;
    theme?: string;
}

const TitleButton = (props: TitleButtonProps & React.HTMLProps<HTMLButtonElement>) => {
    const { title, href, theme } = props;

    const buttonClass = classNames({
        [`title-button`]: true,
        [`title-button--${theme}`]: theme,
    });

    return (
        <Link to={href} className={buttonClass}>
            <span className="title-button__inner">{title}</span>
        </Link>
    );
};

export default TitleButton;
