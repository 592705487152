import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store';
import AppRouter from './AppRouter';
import { Router, Route, Switch } from 'react-router-dom';
import * as createHistory from 'history';
import ReactGA from 'react-ga';
import { makeAuthenticator, makeUserManager, Callback } from 'react-oidc';
import userManagerConfig from './oidcsettings';
import Loader from './components/Loader/Loader';
import { GA_CODE } from './constants/analytics';

// Google analytics setup
const history = createHistory.createBrowserHistory();

ReactGA.initialize(GA_CODE, {
    //debug: process.env.NODE_ENV !== 'production',
    gaOptions: { cookieDomain: 'none' },
});

history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
});

const { persistor, store } = configureStore();
const userManager = makeUserManager(userManagerConfig);

const AppProvider = () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AppRouter />
        </PersistGate>
    </Provider>
);

const AppWithAuth = makeAuthenticator({
    userManager: userManager,
    placeholderComponent: <Loader visible text="Authenticating" />,
})(AppProvider);

const App = () => (
    <Router history={history}>
        {process.env.NODE_ENV !== 'production' ? (
            <AppProvider />
        ) : (
            <Switch>
                <Route
                    path="/callback"
                    exact
                    render={routeProps => (
                        <Callback
                            onSuccess={user => {
                                routeProps.history.push('/?');
                            }}
                            userManager={userManager}
                        />
                    )}
                />
                <AppWithAuth />
            </Switch>
        )}
    </Router>
);

export default App;
