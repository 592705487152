import React from 'react';
import classNames from 'classnames';

interface Props {
    title: string;
    source: string;
    dark?: boolean;
}

const Profile = ({ title, source, dark }: Props) => {
    const profileClasses = classNames({
        [`profile`]: true,
        [`profile__dark`]: dark,
    });

    const profileTitleClasses = classNames({
        [`profile__title`]: true,
        [`profile__title--dark`]: dark,
    });

    return (
        <div className={profileClasses}>
            <img src={source} className="profile__image" alt={title} />
            <div className={profileTitleClasses}>{title}</div>
        </div>
    );
};

export default Profile;
