import _ from 'lodash';
// import moment from 'moment';
import { apiService } from '../constants/apiBase';
import { LOADER_SHOW, LOADER_HIDE } from '../containers/@Shared/Loader/actions/types';
import { fetchPlaybookCoreNav, fetchPlaybookCore } from '../containers/Playbook/actions/playbook_actions';
import { fetchPlaybookPortal } from '../containers/PlaybookPortal/actions/playbook_portal_actions';
import { fetchNews } from '../containers/News/actions/news_actions';
import { fetchBestPractice } from '../containers/BestPractice/actions/best_practice_actions';
import { fetchCapabilityModules } from '../containers/CapabilityModules/actions/capability_module_actions';
import { fetchSwpToolkits } from '../containers/SwpToolkits/actions/swp_toolkits_actions';
import { fetchAssetDownloads } from '../containers/AssetDownloads/actions/asset_downloads_actions';
import { fetchCovid } from '../containers/Covid/actions/covid_actions';
import { fetchOverview } from '../containers/Overview/actions/overview_actions';
import { fetchWelcome } from '../containers/Welcome/actions/welcome_actions';

//import { apiService } from '../../../constants/apiBase';
import { UPDATE_DATE_MODIFIED } from '../containers/@Shared/DateModified/actions/types';

export const fetchAppContent = () => async (dispatch, getState) => {
    try {
        const { data } = await apiService.get('modules/updated');

        const lastUpdated = getState().dateModified;

        console.log('updatedContent', data);
        console.log('setDate', lastUpdated);

        // if (moment(lastUpdated).isBefore(data) || _.isEmpty(lastUpdated)) {
        console.log('Content downloading...');

        if (_.isEmpty(getState().playbookPortal)) {
            dispatch({
                type: LOADER_SHOW,
                text: 'Loading',
            });
        }

        await dispatch(fetchWelcome());
        await dispatch(fetchOverview());
        await dispatch(fetchPlaybookPortal());
        await dispatch(fetchPlaybookCoreNav());
        await dispatch(fetchPlaybookCore());
        await dispatch(fetchCovid());
        await dispatch(fetchNews());
        await dispatch(fetchBestPractice());
        await dispatch(fetchCapabilityModules());
        await dispatch(fetchSwpToolkits());
        await dispatch(fetchAssetDownloads());

        dispatch({
            type: UPDATE_DATE_MODIFIED,
            payload: data,
        });

        console.log('Content downloaded.');
        // }
    } catch (e) {
        console.log(e);
    } finally {
        dispatch({
            type: LOADER_HIDE,
        });
    }
};
