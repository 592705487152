import _ from 'lodash';

export const capabilityModulesSelector = state => {
    return state.capabilityModules;
};

export const capabilityModulesPostSelector = (state, path) => {
    const result = state.capabilityModules.posts.find(obj => {
        return obj.path === path;
    });

    if (result) {
        return result;
    }

    return [];
};

// export const capabilityModulesCategoriesSelector = state => {
//     const categories = _.uniq(_.flatMap(state.capabilityModules.posts, ({ category }) => category.split(',') ));
//     return categories.filter(Boolean);
// };

export const capabilityModulesCategoriesSelector = state => {
    const categories = _.uniq(_.flatMap(state.capabilityModules.posts, ({ category }) => category.split(',')));

    const sortedCollection = _.sortBy(categories, function(item) {
        return state.capabilityModules.filters[0].options.indexOf(item);
    });

    return sortedCollection.filter(Boolean);
};
