import React from 'react';
import classNames from 'classnames';
import { Link } from '../../components';

interface JumbotronButton {
    slot?: string;
    href?: string;
    theme?: string;
}

const JumbotronButton = (props: JumbotronButton & React.HTMLProps<HTMLButtonElement>) => {
    const { theme, children, href, ...rest } = props;

    const buttonClass = classNames({
        [`jumbotron-button`]: true,
        [`jumbotron-button--${theme}`]: theme,
    });

    if (href) {
        return React.createElement(Link, { href, className: buttonClass, ...rest }, children);
    }
    return React.createElement('button', { className: buttonClass, ...rest }, children);
};

export default JumbotronButton;
