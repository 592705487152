import _ from 'lodash';
export const bestPracticeSelector = state => {
    return state.bestPractice;
};

export const bestPracticePostSelector = (state, path) => {
    const result = state.bestPractice.posts.find(obj => {
        return `/research-best-practice/${obj.id}` === path;
    });

    if (result) {
        return result;
    }

    return {};
};

// export const bestPracticeCategoriesSelector = state => {
//     const categories = _.uniq(_.flatMap(state.bestPractice.posts, ({ category }) => category.split(',') ));
//     return categories.filter(Boolean);
// };

// export const bestPracticeCategoriesSelector = state => {
//     const categories = _.uniq(_.flatMap(state.bestPractice.posts, ({ category }) => category.split(',')));

//     const sortedCollection = _.sortBy(categories, function(item) {
//         return state.bestPractice.filters[0].options.indexOf(item);
//     });

//     return sortedCollection.filter(Boolean);
// };

export const bestPracticeCategoriesSelector = state => {
    return state.bestPractice.filters;
};
