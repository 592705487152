import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface NavBoxProps {
    slot?: string;
    children: ReactNode;
    flow: 'row' | 'column';
    expand?: boolean;
}

const NavBox = (props: NavBoxProps) => {
    const { flow, expand, children } = props;

    const navBoxClass = classNames({
        [`nav-box`]: true,
        [`nav-box--${flow}`]: true,
        [`nav-box--expand`]: expand,
    });

    return (
        <div className={navBoxClass}>
            {React.Children.toArray(children).map((child, i) => (
                <div className="nav-box__item" key={i}>
                    {child}
                </div>
            ))}
        </div>
    );
};

NavBox.defaultProps = {
    flow: 'column',
};

export default NavBox;
