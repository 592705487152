import { apiService } from '../../../constants/apiBase';
import { ASSET_DOWNLOADS_RECEIVED } from './types';

export const fetchAssetDownloads = () => async dispatch => {
    try {
        const { data } = await apiService.get('asset_downloads/all');

        dispatch({
            type: ASSET_DOWNLOADS_RECEIVED,
            payload: data,
        });

        //console.log('fetched asset downloads');
    } catch (e) {
        console.log(e);
    }
};
