import _ from 'lodash';
import React from 'react';
import Modal from 'react-modal';

import { Box, Grid, Container } from '@material-ui/core';

import { Title, Panel, Profile } from '../../components';
import Close from '../../Icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { hideWelcome } from './actions/welcome_actions';

const Welcome = () => {
    const { isVisible, entry } = useSelector(state => state.welcome);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideWelcome());
    };

    return (
        <Modal isOpen={isVisible} className="modal" overlayClassName="modal-overlay">
            {!_.isEmpty(entry) && (
                <Container maxWidth="md" style={{ position: 'relative' }}>
                    <button className="modal__close" onClick={handleClose}>
                        <Close width={30} height={30} color="#fff" />
                    </button>

                    <Title h1 theme="primary" fullWidth>
                        {entry.title}
                    </Title>

                    <Panel welcome html={entry.content} />

                    <Box my={5}>
                        <Grid container spacing={1}>
                            {entry.team.map((o, i) => (
                                <Grid key={i} item xs={4} md={3}>
                                    <Profile title={o.name} source={o.image} dark />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Container>
            )}
        </Modal>
    );
};

export default Welcome;
