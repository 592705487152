import * as React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 30 30" {...props}>
        <g fill="currentColor">
            <path d="M14.63 2.95a11.67 11.67 0 10-.01 23.33 11.67 11.67 0 000-23.33zm0-2.95a14.62 14.62 0 110 29.25 14.62 14.62 0 010-29.25zm7.3 19.9l-2.04 2.04-5.27-5.27-5.26 5.27-2.05-2.05 5.27-5.27L7.3 9.37 9.36 7.3l5.27 5.27 5.26-5.27 2.05 2.05-5.27 5.27 5.27 5.26z" />
        </g>
    </svg>
);

export default SvgClose;
