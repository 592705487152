import _ from 'lodash';
import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SvgChevronRight from '../../Icons/ChevronRight';

interface NavProps {
    slot?: string;
    items: any[];
    activePath: string;
    linkComponent?: (href: string) => { type: any; props: object };
}

interface NavState {
    childItems: ReactNode;
}

class Nav extends Component<NavProps, NavState> {
    static defaultProps = {
        activePath: '',
    };

    state = { childItems: null };
    activePath = this.props.activePath;

    componentDidMount() {
        const childItems = this.getChildren(this.props.items);
        this.setState({ childItems });
    }

    componentDidUpdate(prevProps: NavProps) {
        const { activePath, items } = this.props;

        if (activePath !== prevProps.activePath || items !== prevProps.items) {
            const childItems = this.getChildren(items);
            this.setState({ childItems });
        }
    }

    linkComponent = (item: any, isActive: boolean, currentDepth: number) => {
        const { id, title, icon, href } = item;

        const linkClass = classNames({
            [`nav__link`]: true,
            [`nav__link--active`]: isActive,
            [`nav__link--parent`]: currentDepth === 1,
            [`nav__link--child`]: currentDepth === 2,
            [`nav__link--grandchild`]: currentDepth === 3,
            [`nav__link--great-grandchild`]: currentDepth > 3,
            [`nav__link--icon`]: icon,
        });

        const location = {
            pathname: href,
            state: { id },
        };

        return (
            <Link to={location} className={linkClass}>
                {icon ? <img src={icon} className={`nav__icon`} alt="" /> : null}
                {title}
                {currentDepth < 3 && <SvgChevronRight width={8} height={10} className={`nav__chevron`} />}
            </Link>
        );
    };

    hasUrl = (data: any[], activePath: string) => {
        if (_.isEmpty(data)) return false;

        const result = data.some(el => {
            if (el.href === activePath) return true;
            else if (el.children) return this.hasUrl(el.children, activePath);
            return false;
        });

        return result;
    };

    getChildren = (items: any[], currentDepth = 0) => {
        currentDepth++;
        const { activePath } = this.props;

        const childMenuItems = items.map(item => {
            const { href, theme, children } = item;

            const hasActiveChildren = this.hasUrl(children, activePath);
            const isActive = hasActiveChildren || href === activePath;

            const listItemClass = classNames({
                [`nav__item`]: true,
                [`nav__item--${theme}`]: theme,
                [`nav__item--active`]: isActive,
                [`nav__item--parent`]: currentDepth === 1,
                [`nav__item--child`]: currentDepth === 2,
                [`nav__item--grandchild`]: currentDepth === 3,
                [`nav__item--great-grandchild`]: currentDepth > 3,
            });

            const descendantClass = classNames({
                [`nav__descendant`]: true,
                [`nav__descendant--active`]: isActive,
            });

            return (
                <li key={_.uniqueId()} className={listItemClass}>
                    {this.linkComponent(item, isActive, currentDepth)}
                    {children ? <ul className={descendantClass}>{this.getChildren(children, currentDepth)}</ul> : null}
                </li>
            );
        });

        return childMenuItems;
    };

    render() {
        return <ul className="nav">{this.state.childItems}</ul>;
    }
}

export default Nav;
