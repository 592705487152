import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface TitleProps {
    slot?: string;
    theme?: string;
    children: ReactNode;
    h1?: boolean;
    h2?: boolean;
    h3?: boolean;
    h4?: boolean;
    h5?: boolean;
    inverted?: boolean;
    fullWidth?: boolean;
}

const Title = (props: TitleProps) => {
    const { h2, h3, h4, h5, theme, inverted, fullWidth, children } = props;

    const titleClass = classNames({
        [`title`]: true,
        [`title--h1`]: !(h2 || h3 || h4 || h5),
        [`title--h2`]: h2,
        [`title--h3`]: h3,
        [`title--h4`]: h4,
        [`title--h5`]: h5,
        [`title--${theme}`]: theme,
        [`title--inverted`]: inverted,
        [`title--full-width`]: fullWidth,
    });

    let Tag: string;

    if (h2) {
        Tag = 'h2';
    } else if (h3) {
        Tag = 'h3';
    } else if (h4) {
        Tag = 'h4';
    } else if (h5) {
        Tag = 'h5';
    } else {
        // default
        Tag = 'h1';
    }

    return React.createElement(Tag, { className: titleClass }, children);
};

export default Title;
