import * as React from 'react';

const SvgArrowLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 31.49 31.49" {...props}>
        <path
            fill="currentColor"
            d="M10.27 5a1.11 1.11 0 0 1 1.59 0c.43.44.43 1.15 0 1.58l-8.05 8.05h26.56a1.12 1.12 0 1 1 0 2.24H3.8l8.05 8.03c.43.44.43 1.16 0 1.58-.44.45-1.14.45-1.59 0L.32 16.53a1.12 1.12 0 0 1 0-1.57l9.95-9.95z"
        />
    </svg>
);

export default SvgArrowLeft;
