import { apiService } from '../../../constants/apiBase';
import { CAPABILITY_MODULES_RECEIVED } from './types';

//import data from '../../../json/capability-modules.json';

export const fetchCapabilityModules = () => async dispatch => {
    try {
        const { data } = await apiService.get('capability_modules/all');

        dispatch({
            type: CAPABILITY_MODULES_RECEIVED,
            payload: data,
        });

        console.log('fetched capability modules');
    } catch (e) {
        console.log(e);
    }
};
