import { apiService } from '../../../constants/apiBase';
import { SWP_TOOLKITS_RECEIVED } from './types';

export const fetchSwpToolkits = () => async dispatch => {
    try {
        const { data } = await apiService.get('swp_toolkits/all');

        dispatch({
            type: SWP_TOOLKITS_RECEIVED,
            payload: data,
        });

        console.log('fetched swp toolkits');
    } catch (e) {
        console.log(e);
    }
};
