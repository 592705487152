import React, { ReactNode } from 'react';
import reactComponentSlots from '../../utils/reactComponentSlots';
import classNames from 'classnames';

interface PageLayoutProps {
    children?: ReactNode;
    template?: string;
}

/**
 * Available props:
 * - slot (nav, breadcrumbs and sidebar)
 *
 * ```
 * // Any component without a slot prop will be added to the default slot.
 * // e.g.
 * <component slot="nav" />
 * <component slot="breadcrumbs" />
 * <component slot="sidebar" />
 * ```
 */

const PageLayout = (props: PageLayoutProps) => {
    const slots = reactComponentSlots(props);

    const pageClass = classNames({
        [`page`]: true,
        [`page--withnav`]: slots['nav'],
    });

    const pageMainClass = classNames({
        [`page__main`]: true,
        [`page__main--withsidebar`]: slots['sidebar'],
    });

    const pageMainInnerClass = classNames({
        [`page__main-inner`]: true,
        [`page__main-inner--withsidebar`]: slots['sidebar'],
    });

    return (
        <div className={pageClass}>
            {slots['nav'] && <div className="page__nav">{slots['nav']}</div>}
            <div className={pageMainClass}>
                <div className={pageMainInnerClass}>
                    {slots['breadcrumbs'] && <div className="page__breadcrumbs">{slots['breadcrumbs']}</div>}
                    {slots['header'] && <div className="page__header">{slots['header']}</div>}
                    {slots['default'] && <div className="page__article">{slots['default']}</div>}
                    {slots['sidebar'] && <div className="page__sidebar">{slots['sidebar']}</div>}
                    {slots['footer'] && <div className="page__footer">{slots['footer']}</div>}
                </div>
            </div>
        </div>
    );
};

export default PageLayout;
