import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface ButtonGroupProps {
    slot?: string;
    children: ReactNode;
    flow: 'row' | 'column';
    expand?: boolean;
}

const ButtonGroup = (props: ButtonGroupProps) => {
    const { flow, expand, children } = props;

    const buttonGroupClass = classNames({
        [`button-group`]: true,
        [`button-group--${flow}`]: true,
        [`button-group--expand`]: expand,
    });

    return (
        <div className={buttonGroupClass}>
            {React.Children.toArray(children).map((child, i) => (
                <div className="button-group__item" key={i}>
                    {child}
                </div>
            ))}
        </div>
    );
};

ButtonGroup.defaultProps = {
    flow: 'column',
};

export default ButtonGroup;
