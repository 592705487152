import { apiService } from '../../../constants/apiBase';
import { BEST_PRACTICE_RECEIVED } from './types';

//import data from '../../../json/best-practice.json';

export const fetchBestPractice = () => async dispatch => {
    try {
        //const { data } = await apiService.get('best_practices/all');

        const { data } = await apiService.get('research_best_practice/all');

        dispatch({
            type: BEST_PRACTICE_RECEIVED,
            payload: data,
        });

        console.log('fetched best practices');
    } catch (e) {
        console.log(e);
    }
};
