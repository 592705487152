import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from '../../components';

import SvgDownload from '../../Icons/Download';

interface ButtonProps {
    slot?: string;
    external?: boolean;
    href?: string;
    mode: 'dark' | 'light';
    type: 'solid' | 'outline';
    size: 'small' | 'medium' | 'large';
    expand?: boolean;
    children?: ReactNode;
    icon?: 'download';
}

const Button = (props: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
    const { mode, type, size, disabled, expand, icon, children, href, className, ...rest } = props;

    const buttonClass = classNames({
        [`button`]: true,
        [`button--${mode}`]: true,
        [`button--${type}`]: true,
        [`button--${size}`]: true,
        [`button--expand`]: expand,
        [`button--icon`]: icon,
        [`${className}`]: true,
        [`button--disabled`]: disabled,
    });

    let buttonIcon = null;

    switch (icon) {
        case 'download':
            buttonIcon = <SvgDownload width={15} height={15} className="button__icon" />;
    }

    if (href) {
        return React.createElement(Link, { href, className: buttonClass, ...rest }, children, buttonIcon);
    }
    return React.createElement('button', { className: buttonClass, ...rest }, children, buttonIcon);
};

Button.defaultProps = {
    mode: 'dark',
    type: 'solid',
    size: 'medium',
};

export default Button;
