import React, { ReactNode } from 'react';
import classNames from 'classnames';
import SvgChevronRightCircle from '../../Icons/ChevronRightCircle';
import Link from '../Link/Link';

interface HighlightCardProps {
    title: string;
    description?: string;
    backgroundImage?: string;
    href: string;
    theme?: string;
    icon?: ReactNode;
    external?: boolean;
}

const HighlightCard = (props: HighlightCardProps) => {
    const { title, description, href, theme, backgroundImage, icon, external } = props;

    const highlightCardClass = classNames({
        [`highlight-card`]: true,
    });

    const highlightCardMainClass = classNames({
        [`highlight-card__main`]: true,
        [`highlight-card__main--${theme}`]: true,
    });

    const highlightCardTitleClass = classNames({
        [`highlight-card__title`]: true,
        [`highlight-card__title--${theme}`]: true,
    });

    const highlightCardDescriptionClass = classNames({
        [`highlight-card__description`]: true,
        [`highlight-card__description--${theme}`]: true,
    });

    const highlightCardIconClass = classNames({
        [`highlight-card__icon`]: true,
        [`highlight-card__icon--${theme}`]: true,
    });

    const highlightCardFooterClass = classNames({
        [`highlight-card__footer`]: true,
        [`highlight-card__footer--${theme}`]: true,
    });

    return (
        <div className={highlightCardClass}>
            <Link className={highlightCardMainClass} href={href} external={external}>
                <div className="highlight-card__media" style={{ backgroundImage: `url(${backgroundImage})` }} />
                <div className="highlight-card__content">
                    <div className="highlight-card__body">
                        <h2 className={highlightCardTitleClass}>{title}</h2>
                        <p className={highlightCardDescriptionClass}>{description}</p>
                        {icon && <div className={highlightCardIconClass}>{icon}</div>}
                    </div>
                    <div className={highlightCardFooterClass}>
                        <SvgChevronRightCircle width={32} height={32} />
                    </div>
                </div>
            </Link>
        </div>
    );
};

HighlightCard.defaultProps = {
    // mode: 'light',
    // size: 'large',
};

export default HighlightCard;
