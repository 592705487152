import { apiService } from '../../../constants/apiBase';
import { PLAYBOOK_PORTAL_SUCCESS } from './types';

import data from '../../../json/playbook-portal.json';

export const fetchPlaybookPortal = () => async dispatch => {
    try {
        //const { data } = await apiService.get('modules/all');

        dispatch({
            type: PLAYBOOK_PORTAL_SUCCESS,
            payload: data,
        });

        console.log('fetched play book portal');
    } catch (e) {
        console.log(e);
    }
};
