import PERSIST_REHYDRATE from 'redux-persist/lib/constants';
import { CAPABILITY_MODULES_RECEIVED } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case PERSIST_REHYDRATE:
            return action.payload.capabilityModules || state;

        case CAPABILITY_MODULES_RECEIVED: {
            return action.payload;
        }

        // case RESET: {
        //     return initialState;
        // }

        default:
            return state;
    }
};
