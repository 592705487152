import _ from 'lodash';

export const swpToolkitsSelector = state => {
    return state.swpToolkits;
};

export const swpToolkitsPostSelector = (state, path) => {
    const result = state.swpToolkits.posts.find(obj => {
        return obj.path === path;
    });

    if (result) {
        return result;
    }

    return [];
};

export const swpToolkitsCategoriesSelector = state => {
    const categories = _.uniq(_.flatMap(state.swpToolkits.posts, ({ category }) => category.split(',') ));
    return categories.filter(Boolean);
};
