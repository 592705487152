import { WELCOME_HIDE, WELCOME_RECEIVED } from '../actions/types';

const initialState = {
    isVisible: true,
    entry: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case WELCOME_RECEIVED: {
            return {
                ...state,
                entry: action.payload,
            };
        }
        case WELCOME_HIDE: {
            return {
                ...state,
                isVisible: false,
            };
        }
        default:
            return state;
    }
};
