import React, { ReactNode } from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';

interface PanelProps {
    slot?: string;
    children?: ReactNode;
    theme?: string;
    html?: any;
    welcome?: boolean;
}

const Panel = (props: PanelProps) => {
    const { theme, html, children, welcome } = props;

    const panelClass = classNames({
        [`panel`]: true,
        [`panel--${theme}`]: theme,
        [`panel--welcome`]: welcome,
    });

    const panelBodyClass = classNames({
        [`panel__body`]: true,
        [`panel__body--welcome`]: welcome,
    });

    return (
        <div className={panelClass}>
            {html && <div className={panelBodyClass}>{parse(html)}</div>}
            {children && <div className="panel__children">{children}</div>}
        </div>
    );
};

// Panel.defaultProps = {
//     mode: 'light',
// };

export default Panel;
