import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsPostSelector } from './selectors/news_selectors';
import buildComponents from '../../utils/buildComponents';
import { Link } from 'react-router-dom';

import PageLayout from '../../layouts/PageLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Box } from '@material-ui/core';

interface NewsSingularProps {
    id: number;
    components: any;
    title: string;
    path: string;
}

class NewsSingular extends Component<NewsSingularProps> {
    render() {
        const { components, title, path } = this.props;

        return (
            <PageLayout>
                <nav className="breadcrumbs" slot="breadcrumbs">
                    <ul className="breadcrumbs__list">
                        <li className="breadcrumbs__item">
                            <Link className="breadcrumbs__link" to={path}>
                                {title}
                            </Link>
                        </li>
                        <li className="breadcrumbs__item">
                            <span className="breadcrumbs__link">{components[0].children}</span>
                        </li>
                    </ul>
                </nav>

                {buildComponents(components)}

                <Box mb={2} slot="header">
                    <Button
                        component={Link}
                        variant="outlined"
                        color="primary"
                        disableElevation
                        to={path}
                        startIcon={<ArrowBackIcon />}
                    >
                        View All {title}
                    </Button>
                </Box>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    const { id, components } = newsPostSelector(state, props.location.pathname);

    return {
        id,
        components,
        path: state.news.path,
        title: state.news.title,
    };
};

export default connect(mapStateToProps, null)(NewsSingular);
