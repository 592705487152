import React from 'react';
//import classNames from 'classnames';

// TODO: fix the flickering and show some sort of loading view!

interface IframeProps {
    slot?: string;
    //children?: ReactNode;
    response?: boolean;
    aspectRatio?: '16:9';
    src: string;
    width?: string;
    height?: string;
}

// response yes no
// width / height

const Iframe = (props: IframeProps) => {
    const { width, height, src } = props;

    // const IframeClass = classNames({
    //     [`Iframe`]: true,
    //     [`Iframe--${theme}`]: theme,
    // });

    return (
        <div className="iframe">
            <iframe
                src={src}
                width={width}
                height={height}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Iframe"
            />
        </div>
    );
};

// Iframe.defaultProps = {
//     mode: 'light',
// };

export default Iframe;
