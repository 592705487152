import { OVERVIEW_RECEIVED } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case OVERVIEW_RECEIVED: {
            return action.payload;
        }

        default:
            return state;
    }
};
