import _ from 'lodash';
import React, { Fragment } from 'react';

import {
    //Breadcrumbs,
    //Nav,
    //Navbar,
    //Pagination,
    AnimatedSlides,
    Button,
    ButtonGroup,
    Card,
    Carousel,
    ContentModal,
    Divider,
    Gallery,
    HighlightCard,
    Iframe,
    Image,
    Jumbotron,
    JumbotronButton,
    Label,
    Link,
    List,
    ListItem,
    NavBox,
    NavGrid,
    NavTab,
    NavTabs,
    NavTile,
    Panel,
    PanelTabs,
    Poster,
    Title,
    Yammer,
} from '../components';

const components: any = {
    //Breadcrumbs,
    //Nav,
    //Navbar,
    //Pagination,
    AnimatedSlides,
    Button,
    ButtonGroup,
    Card,
    Carousel,
    ContentModal,
    Divider,
    Fragment,
    Gallery,
    HighlightCard,
    Iframe,
    Image,
    Jumbotron,
    JumbotronButton,
    Label,
    Link,
    List,
    ListItem,
    NavBox,
    NavGrid,
    NavTab,
    NavTabs,
    NavTile,
    Panel,
    PanelTabs,
    Poster,
    Title,
    Yammer,
};

const buildComponents = (data: any) => {
    if (!data) return;

    const component: any = (object: any) => {
        const { tag, props, children = [] } = object;

        if (tag in components) {
            const TagName = components[tag];

            return React.createElement(
                TagName,
                { key: _.uniqueId(), ...props },
                Array.isArray(children)
                    ? _.map(children, (child: any) => {
                          return component(child);
                      })
                    : children,
            );
        }
        return null;
    };

    return data.map((object: any, i: number) => {
        return component(object, i);
    });
};

export default buildComponents;
