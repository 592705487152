import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader as LoadingComponent } from '../../../components';

interface Props {
    visible?: boolean;
    text?: string;
}

interface State {}

class Loader extends Component<Props, State> {
    state: Readonly<State> = {};

    render() {
        return <LoadingComponent visible={this.props.visible} text={this.props.text} />;
    }
}

const mapStateToProps = (state: any) => {
    return {
        visible: state.loader.visible,
        text: state.loader.text,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Loader);
