import React, { FunctionComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Navbar, NavbarButton, Link, ToolsMenu } from '../../components';

import logo from '../../assets/images/logo@2x.png';
import SvgArrowLeft from '../../Icons/ArrowLeft';

interface GlobalNavbarProps {
    slot?: string;
}

const GlobalNavbar: FunctionComponent<GlobalNavbarProps & RouteComponentProps> = props => {
    return (
        <Navbar title="Unilever">
            <ToolsMenu slot="before"></ToolsMenu>
            {props.location.pathname !== '/' && (
                <NavbarButton href="/" slot="before">
                    <SvgArrowLeft width="1rem" height="1rem" className="icon" />
                    Home
                </NavbarButton>
            )}
            <img src={logo} alt="" slot="title" />
            <Link href="/" slot="after">
                {/* Menu */}
            </Link>
        </Navbar>
    );
};

export default withRouter(GlobalNavbar);
