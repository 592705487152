import React, { ReactNode } from 'react';
//import classNames from 'classnames';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

interface PanelTabsProps {
    slot?: string;
    children: ReactNode;
    tabs: string[];
}

const PanelTabs = (props: PanelTabsProps) => {
    const { tabs, children } = props;

    return (
        <Tabs
            className="panel-tabs"
            selectedTabClassName="panel-tabs__tab--selected"
            disabledTabClassName="panel-tabs__tab--disabled"
            selectedTabPanelClassName="panel-tabs__tab-panel--selected"
        >
            <TabList className="panel-tabs__tab-list">
                {tabs.map((tab, key) => (
                    <Tab key={key} className="panel-tabs__tab">
                        <h3 className="panel-tabs__tab-text">{tab}</h3>
                    </Tab>
                ))}
            </TabList>

            {React.Children.map(children, (child, i) => (
                <TabPanel key={i} className="panel-tabs__tab-panel">
                    {child}
                </TabPanel>
            ))}
        </Tabs>
    );
};

export default PanelTabs;
