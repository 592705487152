import React, { Component } from 'react';

import { Title, HighlightCard } from '../../components';

import { Container, Grid, Box } from '@material-ui/core';
import VirtualXP from '../../Icons/VirtualXP';
import AssetXP from '../../Icons/AssetXP';
import CategoryXP from '../../Icons/CategoryXP';

// interface Props {
//     tools: any;
// }

class ShopperXP extends Component {
    render() {
        return (
            <Container>
                <Box mt={5}>
                    <Title theme="primary">BPC Everyday Data-driven Great Integrated Execution Tools</Title>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <HighlightCard
                            title="VirtualXP"
                            href="https://virtualxp-unilever.azurewebsites.net/"
                            backgroundImage="https://unileverplaybook.s3.eu-west-2.amazonaws.com/covid-landing-example@2x.png"
                            theme="primary"
                            icon={<VirtualXP width="70" height="70" />}
                            external
                        />
                    </Grid>

                    {/* <Grid item xs={4}>
                        <HighlightCard
                            title="AssetXP"
                            href="https://assetxp-unilever.azurewebsites.net/"
                            backgroundImage="https://unileverplaybook.s3.eu-west-2.amazonaws.com/covid-landing-example@2x.png"
                            theme="primary"
                            icon={<AssetXP width="70" height="70" />}
                            external
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <HighlightCard
                            title="CategoryXP"
                            href="https://categoryxp-activxp.azurewebsites.net/"
                            backgroundImage="https://unileverplaybook.s3.eu-west-2.amazonaws.com/covid-landing-example@2x.png"
                            theme="primary"
                            icon={<CategoryXP width="70" height="70" />}
                            external
                        />
                    </Grid> */}
                </Grid>
            </Container>
        );
    }
}

export default ShopperXP;
