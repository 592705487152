import React, { forwardRef } from 'react';
//import classNames from 'classnames';
import SvgSearch from '../../Icons/Search';

type SearchBoxProps = {
    slot?: string;
} & React.HTMLProps<HTMLFormElement> &
    React.HTMLProps<HTMLInputElement> &
    React.HTMLProps<HTMLButtonElement>;

const SearchBox = (props: SearchBoxProps, ref: any) => {
    const { onSubmit, ...rest } = props;

    // const searchClass = classNames({
    //     [`input`]: true,
    //     [`input--${type}`]: type,
    // });

    return (
        <div className="search-box">
            <form onSubmit={onSubmit} className="search-box__form">
                <input type="search" className="search-box__input" {...rest} ref={ref} />
                <button type="submit" className="search-box__button">
                    <SvgSearch className="search-box__icon" width={20} height={20} />
                </button>
            </form>
        </div>
    );
};

export default forwardRef(SearchBox);
