import * as React from 'react';

const SvgChevronRight = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 12 22" {...props}>
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M1 21l10-10L1 1"
        />
    </svg>
);

export default SvgChevronRight;
