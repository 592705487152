import { apiService } from '../../../constants/apiBase';
import { OVERVIEW_RECEIVED } from './types';

export const fetchOverview = () => async dispatch => {
    try {
        const { data } = await apiService.get('overview/all');

        dispatch({
            type: OVERVIEW_RECEIVED,
            payload: data,
        });

        console.log('fetched overview');
    } catch (e) {
        console.log(e);
    }
};
