import React, { Component } from 'react';
import classNames from 'classnames';
// import { Swipeable } from 'react-swipeable';
// import SvgChevronLeft from '../../Icons/ChevronLeft';
// import SvgChevronRight from '../../Icons/ChevronRight';

interface CarouselProps {
    slot?: string;
    children: any;
    slidesPerPage: number;
}

interface CarouselState {
    containerWidth: any;
    trackWidth: any;
    trackOffset: any;
    slideWidth: any;
    startSlide: any;
}

class Carousel extends Component<CarouselProps, CarouselState> {
    static defaultProps = {
        slidesPerPage: 4,
    };

    state = {
        containerWidth: 0,
        trackWidth: 0,
        trackOffset: 0,
        slideWidth: 0,
        startSlide: 0,
    };

    slideCount = this.props.children.length;

    carousel = React.createRef<any>();

    calcDimensions = () => {
        const clientWidth = this.carousel.current.clientWidth;

        const containerWidth = clientWidth;
        const slideWidth = clientWidth / this.props.slidesPerPage;
        const trackWidth = slideWidth * this.slideCount;

        this.setState({ containerWidth, slideWidth, trackWidth });
    };

    onPreviousPress = () => {
        this.setState((prevState: any) => {
            const { startSlide, slideWidth } = prevState;

            if (startSlide > 0) {
                const newSlideStart = startSlide - 1;

                return { startSlide: newSlideStart, trackOffset: slideWidth * newSlideStart };
            }
            return prevState;
        });
    };

    onNextPress = () => {
        this.setState((prevState: any) => {
            const { startSlide, slideWidth } = prevState;

            if (startSlide < this.slideCount - this.props.slidesPerPage) {
                const newSlideStart = startSlide + 1;

                return { startSlide: newSlideStart, trackOffset: slideWidth * newSlideStart };
            }
            return prevState;
        });
    };

    onSwipe = (eventData: any) => {
        //console.log(eventData);
    };

    componentDidMount() {
        this.calcDimensions();
        window.addEventListener('resize', this.calcDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcDimensions);
    }

    render() {
        const { slideWidth, trackWidth } = this.state;

        const listClass = classNames({
            [`carousel__list`]: true,
        });

        const trackClass = classNames({
            [`carousel__track`]: true,
        });

        const slideClass = classNames({
            [`carousel__slide`]: true,
        });

        //if (children) return;

        //console.log(this.state.trackOffset);

        return (
            <div className="carousel">
                {/* <Swipeable onSwiped={eventData => this.onSwipe(eventData)}> */}
                {/* <div className="carousel__nav carousel__nav--prev">
                    <button className="carousel__button" onClick={this.onPreviousPress}>
                        <SvgChevronLeft width={12} height={22} />
                    </button>
                </div> */}
                <div className={listClass} ref={this.carousel}>
                    <div
                        className={trackClass}
                        style={{
                            width: trackWidth,
                            transform: `translate3d(-${this.state.trackOffset}px, 0px, 0px)`,
                        }}
                    >
                        {this.props.children.map((child: any, i: number) => (
                            <div key={i} className={slideClass} style={{ width: slideWidth }}>
                                {child}
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="carousel__nav carousel__nav--next">
                    <button className="carousel__button" onClick={this.onNextPress}>
                        <SvgChevronRight width={12} height={22} />
                    </button>
                </div> */}
                {/* </Swipeable> */}
            </div>
        );
    }
}

export default Carousel;
