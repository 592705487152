import axios from 'axios';

export const apiService = axios.create({
    //baseURL: 'https://admin.healthandbeautyplaybook.com/wp-json/playbook/v1/',
    baseURL: 'https://unilever-playbook.dc-activ.com/wp-json/playbook/v1/',
    headers: {
        Authorization: 'Bearer jYW6d7OW&vC178Ke@3I^6',
        'content-type': 'text/json',
    },
});
