import thunk from 'redux-thunk';
//import logger from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import assetDownloads from '../containers/AssetDownloads/reducers/asset_downloads_reducers';
import bestPractice from '../containers/BestPractice/reducers/best_practice_reducer';
import capabilityModules from '../containers/CapabilityModules/reducers/capability_modules_reducers';
import covid from '../containers/Covid/reducers/covid_reducers';
import dateModified from '../containers/@Shared/DateModified/reducer/date_modified_reducer';
import loader from '../containers/@Shared/Loader/reducer/loader_reducer';
import news from '../containers/News/reducers/news_reducers';
import overview from '../containers/Overview/reducers/overview_reducer';
import playbookCore from '../containers/Playbook/reducers/playbook_reducer';
import playbookCoreNav from '../containers/Playbook/reducers/playbook_nav_reducer';
import playbookPortal from '../containers/PlaybookPortal/reducers/playbook_portal_reducer';
import swpToolkits from '../containers/SwpToolkits/reducers/swp_toolkits_reducers';
import welcome from '../containers/Welcome/reducer/welcome_reducer';

const config = {
    key: 'unilever-playbook',
    storage,
    blacklist: ['loader'],
};

const reducer = persistCombineReducers(config, {
    overview,
    assetDownloads,
    bestPractice,
    capabilityModules,
    covid,
    dateModified,
    loader,
    news,
    playbookCore,
    playbookCoreNav,
    playbookPortal,
    swpToolkits,
    welcome,
});

export default (initialState = {}): any => {
    const store = createStore(reducer, initialState, applyMiddleware(thunk));

    const persistor = persistStore(store);
    return {
        persistor,
        store,
    };
};
