import React, { Component } from 'react';
import { connect } from 'react-redux';

import { covidSelector } from './selectors/covid_selectors';

import { NavGrid, HighlightCard } from '../../components';
import Title from '../../components/Title/Title';
import { Container, Grid, Box } from '@material-ui/core';

interface Props {
    archive: any;
}

class CovidArchive extends Component<Props> {
    render() {
        const { archive } = this.props;

        return (
            <Container>
                <Box mt={5}>
                    <Title theme="primary">{archive.title}</Title>
                </Box>
                <Grid container spacing={4}>
                    {archive.posts.map((post, i) => (
                        <Grid key={i} item xs={12} sm={6} md={4}>
                            <HighlightCard
                                title={post.title}
                                href={`/covid19/${post.id}`}
                                backgroundImage={post.image}
                                theme="primary"
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    return {
        archive: covidSelector(state),
    };
};

export default connect(mapStateToProps, null)(CovidArchive);
