import React from 'react';
import Slider from 'react-slick';

import background from '../../assets/images/as-watsons-bkgd@2x.png';
import icon from '../../assets/images/as-watsons-button.png';

interface AnimatedSlidesProps {
    slot?: string;
    src: string;
    link: string;
    external: boolean;
    width: number;
    height: number;
    target?: string;
}

const AnimatedSlides = (props: AnimatedSlidesProps) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
    };

    const { src, link, width, height, target } = props;

    return (
        <div className="animated-slide" style={{ backgroundImage: `url(${background})` }}>
            <a href="https://aswdarestrategy.dc-activ.com/" target={target}>
                <Slider {...settings}>
                    <div className="animated-slide__slide">
                        <h2 className="animated-slide__title">AS Watson</h2>
                        <h3 className="animated-slide__title animated-slide__title--alt">
                            Repeatable <br />
                            Modal Portal
                        </h3>
                        <div className="animated-slide__icon">
                            <img src={icon} />
                        </div>
                    </div>
                    <div className="animated-slide__slide">
                        <h3 className="animated-slide__title">
                            The Strategic Choices Behind Our Growth &amp; Partnership
                        </h3>
                    </div>
                </Slider>
            </a>
        </div>
    );
};

export default AnimatedSlides;
