import * as React from 'react';

const SvgChevronLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 12 22" {...props}>
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 1L1 11l10 10"
        />
    </svg>
);

export default SvgChevronLeft;
