import React, { Component } from 'react';

interface PaginationProps {
    totalItems: number;
    currentPage: number;
    pageLimit: number;
    onPageChange: Function;
}

const LEFT_PAGE = -1;
const RIGHT_PAGE = 0;

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */

const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

class Pagination extends Component<PaginationProps> {
    static defaultProps = {
        currentPage: 1,
    };

    pageNeighbours = 1;

    totalPages() {
        return Math.ceil(this.props.totalItems / this.props.pageLimit);
    }

    gotoPage = (page: number) => {
        const currentPage = Math.max(0, Math.min(page, this.totalPages()));
        this.props.onPageChange(currentPage);
    };

    handleClick = (page: number) => (event: any) => {
        //event.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = (event: any) => {
        //event.preventDefault();
        this.gotoPage(this.props.currentPage - this.pageNeighbours * 2 - 1);
    };

    handleMoveRight = (event: any) => {
        //event.preventDefault();
        this.gotoPage(this.props.currentPage + this.pageNeighbours * 2 + 1);
    };

    /**
     * Let's say we have 10 pages and we set pageNeighbours to 2
     * Given that the current page is 6
     * The pagination control will look like the following:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => terminal pages: first and last page(always visible)
     * [x] => represents current page
     * {...x} => represents page neighbours
     */
    fetchPageNumbers = () => {
        const totalPages = this.totalPages();
        //const currentPage = this.props.currentPage;
        const pageNeighbours = this.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = this.pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        const { currentPage } = this.props;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages: (number)[] = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case !hasLeftSpill && hasRightSpill: {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    render() {
        if (!this.props.totalItems || this.totalPages() === 1 || this.props.pageLimit === null) return null;

        const { currentPage } = this.props;
        const pages = this.fetchPageNumbers();

        return (
            <nav className="pagination" aria-label="Pagination">
                <ul className="pagination__list">
                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE)
                            return (
                                <li className="pagination__item" key={index}>
                                    <button
                                        className="pagination__button"
                                        aria-label="Previous"
                                        onClick={this.handleMoveLeft}
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                        {/*  <span className="sr-only">Previous</span> */}
                                    </button>
                                </li>
                            );

                        if (page === RIGHT_PAGE)
                            return (
                                <li className="pagination__item" key={index}>
                                    <button
                                        className="pagination__button"
                                        aria-label="Next"
                                        onClick={this.handleMoveRight}
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                        {/* <span className="sr-only">Next</span> */}
                                    </button>
                                </li>
                            );

                        return (
                            <li className="pagination__item" key={index}>
                                <button
                                    className={`pagination__button ${currentPage === page &&
                                        `pagination__button--active`}`}
                                    onClick={this.handleClick(page)}
                                >
                                    {page}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        );
    }
}

export default Pagination;
