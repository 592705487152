import { apiService } from '../../../constants/apiBase';
import { WELCOME_HIDE, WELCOME_RECEIVED } from './types';

export const fetchWelcome = () => async dispatch => {
    try {
        const { data } = await apiService.get('welcome/all');

        dispatch({
            type: WELCOME_RECEIVED,
            payload: data.posts[0],
        });

        console.log('fetched welcome');
    } catch (e) {
        console.log(e);
    }
};

export const hideWelcome = () => ({
    type: WELCOME_HIDE,
});
