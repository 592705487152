import React from 'react';
import classNames from 'classnames';
import SvgTick from '../../Icons/Tick';

interface CheckboxProps {
    slot?: string;
    label: string;
    checked: boolean;
}

const Checkbox = (props: CheckboxProps & React.HTMLProps<HTMLInputElement>) => {
    const { label, ...rest } = props;

    const iconClass = classNames({
        [`checkbox__icon`]: true,
        [`checkbox__icon--checked`]: props.checked,
    });

    const labelClass = classNames({
        [`checkbox__label`]: true,
    });

    return (
        <div className="checkbox">
            <label>
                <input type="checkbox" {...rest} className="checkbox__hidden" />
                <div className={iconClass}>
                    <SvgTick />
                </div>
                <span className={labelClass}>{label}</span>
            </label>
        </div>
    );
};

export default Checkbox;
