import _ from 'lodash';

export const newsSelector = state => {
    return state.news;
};

export const newsPostSelector = (state, path) => {
    const result = state.news.posts.find(obj => {
        return obj.path === path;
    });

    if (result) {
        return result;
    }

    return [];
};

// export const newsCategoriesSelector = state => {
//     const categories = _.uniq(_.flatMap(state.news.posts, ({ category }) => category.split(',')));
//     return categories.filter(Boolean);
// };

export const newsCategoriesSelector = state => {
    const categories = _.uniq(_.flatMap(state.news.posts, ({ category }) => category.split(',')));

    const sortedCollection = _.sortBy(categories, function(item) {
        return state.news.filters[0].options.indexOf(item);
    });

    return sortedCollection.filter(Boolean);
};
