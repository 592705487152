import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bestPracticeSelector } from './selectors/best_practice_selectors';

import { NavGrid, HighlightCard, Title } from '../../components';
import { Container, Grid, Box } from '@material-ui/core';

interface Props {
    archive: any;
}

class BestPracticeArchive extends Component<Props> {
    render() {
        const { archive } = this.props;

        return (
            <Container>
                <Box mt={5}>
                    <Title theme="primary">{archive.title}</Title>
                </Box>
                <Grid container spacing={4}>
                    {archive.posts.map((post, i) => (
                        <Grid key={i} item xs={12} md={4}>
                            <HighlightCard
                                title={post.title}
                                href={`/research-best-practice/${post.id}`}
                                backgroundImage={post.image}
                                theme="primary"
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    console.log(state.bestPractice);
    return {
        archive: bestPracticeSelector(state),
    };
};

export default connect(mapStateToProps, null)(BestPracticeArchive);
