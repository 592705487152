import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { covidPostSelector, covidFiltersSelector } from './selectors/covid_selectors';
import ReactGA from 'react-ga';

import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

import parse from 'html-react-parser';

import { Title } from '../../components';

import {
    Container,
    Paper,
    Box,
    Button,
    Typography,
    Card,
    CardHeader,
    CardContent,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    InputAdornment,
    Grid,
    Hidden,
} from '@material-ui/core';

import Pagination from '@material-ui/lab/Pagination';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';

const SEARCH_OPTIONS = {
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    findAllMatches: true,
    threshold: 0,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'excerpt'], // Update this based on fields
};

interface Props {
    post: any;
    filters: any;
}

interface State {
    currentPageData: any[];
    currentPage: number;
    filters: any[];
    searchTerm: string;
    offset: number;
    pageCount: number;
    perPage: number;
}

class CovidSingular extends Component<Props, State> {
    state: Readonly<State> = {
        currentPageData: [],
        currentPage: 1,
        filters: [],
        searchTerm: '',
        offset: 0,
        pageCount: 0,
        perPage: 8,
    };

    handleData = () => {
        // Set datasource here
        let data = this.props.post.downloads;

        const { perPage, offset, filters, searchTerm } = this.state;

        if (searchTerm) {
            const fuse = new Fuse(data, SEARCH_OPTIONS);
            data = fuse.search(searchTerm);
        }

        if (filters.length > 0) {
            data = _.filter(data, d => _.every(d.category, c => filters.includes(c)));
        }

        const currentPageData = data.slice(offset, offset + perPage);

        this.setState({
            pageCount: Math.ceil(data.length / perPage),
            currentPageData,
        });

        scroll.scrollToTop();
    };

    handlePageChange = (_, currentPage) => {
        const offset = (currentPage - 1) * this.state.perPage;
        this.setState(
            {
                currentPage,
                offset,
            },
            () => {
                this.handleData();
            },
        );
    };

    handleFilterChange = filter => {
        this.setState(
            prevState => ({ filters: _.xor(prevState.filters, [filter]), offset: 0, currentPage: 1 }),
            () => {
                this.handleData();
            },
        );
    };

    handleSearchSubmit = event => {
        this.setState(
            {
                searchTerm: event.target.value,
            },
            () => {
                this.handleData();
            },
        );
    };

    componentDidMount() {
        this.handleData();
    }

    render() {
        const { title } = this.props.post;
        const { currentPageData } = this.state;

        return (
            <Container>
                <Grid container>
                    <Hidden xsDown>
                        <Grid item md={2}></Grid>
                    </Hidden>
                    <Grid item md={7}>
                        <Box mt={5}>
                            <Title theme="primary">{title}</Title>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <Button
                            component={Link}
                            variant="outlined"
                            color="primary"
                            disableElevation
                            to="/covid19"
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        {currentPageData.length > 0 ? (
                            <>
                                <Grid container spacing={3}>
                                    {currentPageData.map((item, i) => (
                                        <Grid key={i} item xs={12} md={6}>
                                            <Paper style={{ height: '100%' }}>
                                                <Box p={2}>
                                                    <Typography variant="h3">{item.title}</Typography>

                                                    {item.thumb && <img src={item.thumb} width="100%" height="auto" />}
                                                    {item.excerpt && <Box my={2}>{parse(item.excerpt)}</Box>}
                                                    {item.download && (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            endIcon={<CloudDownloadIcon />}
                                                            href={item.download}
                                                            target="_blank"
                                                            //onClick={() => ReactGA.outboundLink({ label: 'Download - xxx' }, null)}
                                                        >
                                                            Download
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Box my={5}>
                                    <Paper>
                                        <Box p={1} display="flex" justifyContent="center">
                                            <Pagination
                                                count={this.state.pageCount}
                                                color="primary"
                                                shape="rounded"
                                                onChange={this.handlePageChange}
                                                page={this.state.currentPage}
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                            </>
                        ) : (
                            <Grid item md={12}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography>No results found</Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Card>
                                    <CardHeader title="Search" />
                                    <CardContent>
                                        <TextField
                                            id="search"
                                            label="Keyword"
                                            onChange={this.handleSearchSubmit}
                                            variant="filled"
                                            fullWidth
                                            color="secondary"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        //onClick={() => console.log('clicked')}
                                                    >
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>

                            {this.props.filters.map((item, i) => (
                                <Grid key={i} item>
                                    <Card slot="sidebar">
                                        <CardHeader title="Filters" />
                                        <CardContent>
                                            <FormGroup>
                                                {item.options.map((filter, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.filters.includes(filter)}
                                                                onChange={() => this.handleFilterChange(filter)}
                                                                name={filter}
                                                            />
                                                        }
                                                        label={filter}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    return {
        post: covidPostSelector(state, props.location.pathname),
        filters: covidFiltersSelector(state),
    };
};

export default connect(mapStateToProps, null)(CovidSingular);
