import * as React from 'react';

const VirtualXP = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 70.82 71.1" {...props}>
        <g fill="currentColor" data-name="Group 6084">
            <path
                d="M42.17 17.51a5.56 5.56 0 10-5.56-5.56 5.57 5.57 0 005.56 5.56zm0-8.9a3.34 3.34 0 11-3.35 3.34 3.34 3.34 0 013.35-3.34z"
                data-name="Path 66"
            />
            <path
                d="M63.25 35.53v2.22h2.43l-10.58 6.7V29.25l1.62-.76a3.48 3.48 0 001.67-4.67 3.5 3.5 0 00-3.3-1.96V11.49h15.59l-3.61-5.74L70.67 0h-17.8v22.6l-4.44 2.12-.01-.02a1.74 1.74 0 01-1.65-.42c-1.7-1.58-4.67-4.28-5.52-4.64l-4.96-2.1a4.9 4.9 0 00-4.01.25l-1 .27a17.93 17.93 0 00-11.95 10.52 16.55 16.55 0 00-.82 2.7 2.72 2.72 0 00-.05.73 3.3 3.3 0 002.34 2.96 3.4 3.4 0 003.73-1.15 3.25 3.25 0 00.54-.97 14.73 14.73 0 014.6-6.62A19.17 19.17 0 0028.31 31l-.32 4.23v26.41l-1.06.67-6.41-7.79-.84.6A1705.94 1705.94 0 010 69.1l.53.97.42 1.03c.16-.06.53-.21 19.17-13.56l6.35 7.7 1.52-.96v.15l5.6-3.7 7.47-4.74v.03l7.13-4.53v-.02l19.27-12.23-.74 2.64 2.13.6 1.97-6.95zM55.1 2.22h11.57l-2.22 3.52 2.21 3.53H55.1zm1.3 22.55a1.27 1.27 0 01-.6 1.7l-.7.32v-2.7a1.25 1.25 0 011.3.68zM30.2 35.32l.32-4.14c.13-1.74 1.72-5.42 2.32-6.68l.9-1.92-2.1.35c-4.1.7-7.54 5.8-8.67 9.22a1.1 1.1 0 01-.19.3 1.2 1.2 0 01-1.32.4 1.07 1.07 0 01-.78-.98.57.57 0 010-.14 14.4 14.4 0 01.7-2.33 15.7 15.7 0 0110.48-9.2l1.07-.3.12-.03c1.1-.45 1.68-.59 2.38-.3l4.93 2.1a59.83 59.83 0 014.96 4.28 4.06 4.06 0 003.64.9l.03.06 3.89-1.85v2.76l-3.14 1.46a5.31 5.31 0 01-4.95-.47l-5.05-4.03-.64 1.15a32.75 32.75 0 00-1.63 3.53 20.22 20.22 0 00-.75 2.72l-.1.56 8.87 9.4a1.73 1.73 0 01.48 1.19v6.92l-2.7 1.71v-6.73a3.92 3.92 0 00-1.14-2.77l-6.45-6.54H32.9v22.6l-.55.36-2.15 1.36zm4.93 21.8v-18.6l5.43 5.5a1.73 1.73 0 01.5 1.22v8.12zm11.98-16.5l-8.09-8.56a17.2 17.2 0 01.54-1.86c.19-.52.55-1.33.89-2.02l3.03 2.41.12.09a7.5 7.5 0 007.08.61l2.2-1.02v15.6l-4.7 2.97v-5.51a3.93 3.93 0 00-1.07-2.71z"
                data-name="Path 67"
            />
        </g>
    </svg>
);

export default VirtualXP;
