import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import buildComponents from '../../utils/buildComponents';
import { playBookPostSelector, playbookCoreNavSelector } from './selectors/playbook_selectors';
import PageLayout from '../../layouts/PageLayout';
import FullWidthLayout from '../../layouts/FullWidthLayout';
import { Nav, Breadcrumbs } from '../../components';
import { Drawer, Box, Hidden, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';

interface PlaybookProps {
    id: number;
    components: any;
    nav: any;
    location: { pathname: string };
    template: string;
    classes: any;
}

// const container = window !== undefined ? () => window<any>().document.body : undefined;

const styles = {
    drawer: {
        width: 240,
        backgroundColor: '#333333',
    },
};

class Playbook extends Component<PlaybookProps> {
    state = {
        drawerOpen: false,
    };

    handleDrawerToggle = () => {
        this.setState((prevState: any) => ({
            drawerOpen: !prevState.drawerOpen,
        }));
    };

    render() {
        const { id, components, template, nav } = this.props;

        if (template === 'fullwidth') {
            return <FullWidthLayout>{buildComponents(components)}</FullWidthLayout>;
        } else {
            return (
                <PageLayout>
                    <Hidden mdUp>
                        <Drawer
                            // container={container}
                            variant="temporary"
                            anchor="left"
                            open={this.state.drawerOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: this.props.classes.drawer,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <Nav items={nav} activePath={this.props.location.pathname} />
                        </Drawer>
                    </Hidden>

                    <div slot="breadcrumbs">
                        <Hidden mdUp>
                            <Box mb={2}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    startIcon={<MenuIcon style={{ fontSize: 14 }} />}
                                    onClick={this.handleDrawerToggle}
                                >
                                    Menu
                                </Button>
                            </Box>
                        </Hidden>
                    </div>

                    <Breadcrumbs items={nav} activeId={id} slot="breadcrumbs" />
                    <Nav slot="nav" items={nav} activePath={this.props.location.pathname} />

                    {buildComponents(components)}
                </PageLayout>
            );
        }
    }
}

// TODO: use id to select content "this.props.location.state.id";

const mapStateToProps = (state: any, props: any) => {
    const { id, components, template } = playBookPostSelector(state, props.location.pathname);
    const nav = playbookCoreNavSelector(state);

    return {
        id,
        components,
        template,
        nav,
    };
};

export default compose(withStyles(styles), connect(mapStateToProps, null))(Playbook);
