import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface FullWidthLayoutProps {
    children?: ReactNode;
}

const FullWidthLayout = (props: FullWidthLayoutProps) => {
    //const slots = reactComponentSlots(props);

    const fullWidthClass = classNames({
        [`fullwidth`]: true,
    });

    return <div className={fullWidthClass}>{props.children}</div>;
};

export default FullWidthLayout;
