import React, { ReactNode } from 'react';

interface LabelProps {
    slot?: string;
    children?: ReactNode;
}

const Label = (props: LabelProps) => {
    return <span className="label">{props.children}</span>;
};

export default Label;
