import React from 'react';
import classNames from 'classnames';
import { Link } from '../../components';
import Tooltip from 'react-tooltip-lite';

interface NavTileProps {
    external?: boolean;
    href: string;
    image?: string | null;
    theme?: string;
    title?: string;
    description?: string | null;
    size: 'small' | 'medium' | 'large';
    imageHeight?: string;
    imageWidth?: string;
}

const NavTile = (props: NavTileProps) => {
    const { title, description, image, theme, size, imageHeight, imageWidth, ...rest } = props;

    const tileClass = classNames({
        [`nav-tile`]: true,
        [`nav-tile--${size}`]: size !== 'medium',
    });

    const titleClass = classNames({
        [`nav-tile__title`]: true,
        [`nav-tile__title--${theme}`]: theme,
        [`nav-tile__title--${size}`]: size !== 'medium',
    });

    const imageClass = classNames({
        [`nav-tile__image`]: true,
        [`nav-tile__image--${size}`]: size !== 'medium',
    });

    return (
        <div className={tileClass}>
            {description ? (
                <Tooltip content={description} distance={12} zIndex={1000} onToggle={null}>
                    <Link title={props.title} {...rest} className="nav-tile__main">
                        <span data-tip={description}>
                            {image && (
                                <div className={imageClass}>
                                    <img src={image} alt="" width={imageWidth} height={imageHeight} />
                                </div>
                            )}
                            <div className={titleClass}>{title}</div>
                        </span>
                    </Link>
                </Tooltip>
            ) : (
                <Link title={props.title} {...rest} className="nav-tile__main">
                    <span data-tip={description}>
                        {image && (
                            <div className={imageClass}>
                                <img src={image} alt="" width={imageWidth} height={imageHeight} />
                            </div>
                        )}
                        <div className={titleClass}>{title}</div>
                    </span>
                </Link>
            )}
        </div>
    );
};

export default NavTile;

NavTile.defaultProps = {
    size: 'medium',
};
