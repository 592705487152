import PERSIST_REHYDRATE from 'redux-persist/lib/constants';
import { PLAYBOOK_PORTAL_SUCCESS } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case PERSIST_REHYDRATE:
            return action.payload.playbookPortal || state;

        case PLAYBOOK_PORTAL_SUCCESS: {
            return { ...action.payload };
        }

        // case RESET: {
        //     return initialState;
        // }

        default:
            return state;
    }
};
