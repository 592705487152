import _ from 'lodash';
import React, { Component } from 'react';
// import Lightbox from 'react-image-lightbox';
import { Button, Pagination, LightboxGallery } from '../../components';

interface GalleryProps {
    title?: string;
    subtitle?: string;
    pageLimit: number;
    images: any[];
}

interface GalleryState {
    currentPage: number;
    filters: string[];
    currentFilter: string;
    lightboxVisible: boolean;
    openImageIndex: number;
    filteredImages: any[];
    currentPageImages: any[];
}

/**
 * Available props:
 * - title
 * - subtitle
 * - pageLimit (paginates gallery images)
 * - images (array)
 *
 * ```
 * // e.g.
 * const images = [{thumb: 'imageUrl', original: 'imageUrl', category: 'Category'}];
 *
 * <Gallery title="Title" subtitle="Subtitle" pageLimit={6} images={images} />
 * ```
 */

class Gallery extends Component<GalleryProps, GalleryState> {
    static defaultProps = {
        pageLimit: 6,
    };

    state = {
        currentPage: 1,
        filters: [],
        currentFilter: 'All',
        lightboxVisible: false,
        openImageIndex: 0,
        filteredImages: this.props.images,
        currentPageImages: [],
    };

    setupFilters() {
        const filters = _(this.props.images)
            .uniqBy('category')
            .map(image => image.category)
            .compact()
            .value();

        if (filters.length) {
            this.setState({ filters: ['All', ...filters] });
        }
    }

    filterImages() {
        // Reset pagination
        this.handlePageChange(1);

        const { currentFilter } = this.state;
        const { images } = this.props;

        if (currentFilter !== 'All') {
            const filteredImages = images.filter(image => {
                return currentFilter.includes(image.category);
            });

            this.setState({ filteredImages });
        } else {
            this.setState({ filteredImages: images });
        }
    }

    paginateImages() {
        const { filteredImages, currentPage } = this.state;
        const { pageLimit } = this.props;

        const pageOffset = (currentPage - 1) * pageLimit;
        const currentPageImages = filteredImages.slice(pageOffset, pageOffset + pageLimit);

        this.setState({ currentPageImages });
    }

    componentDidMount() {
        this.setupFilters();
        this.paginateImages();
    }

    handleFilterChange = (currentFilter: string) => {
        this.setState({ currentFilter });

        this.setState({ currentFilter }, () => {
            this.filterImages();
        });
    };

    handlePageChange = (currentPage: number) => {
        this.setState({ currentPage }, () => {
            this.paginateImages();
        });
    };

    handleLightboxClose = () => {
        this.setState({ lightboxVisible: false });
    };

    handleImageClick = (e: any, id: number) => {
        e.preventDefault();

        const openImageIndex = _.findIndex(this.state.filteredImages, { id });
        this.setState({ openImageIndex, lightboxVisible: true });
    };

    renderImages = () => {
        const { currentPageImages } = this.state;

        return currentPageImages.map(({ id, thumb, url }, i: number) => {
            return (
                <a key={i} href={url} onClick={e => this.handleImageClick(e, id)}>
                    <img alt="" src={thumb} />
                </a>
            );
        });
    };

    render() {
        const { currentFilter, currentPage, filteredImages, lightboxVisible, openImageIndex } = this.state;
        const { title, subtitle, pageLimit } = this.props;
        const imageCount = filteredImages.length;

        return (
            <div className="gallery">
                <h4 className="gallery__title">{title}</h4>
                <p className="gallery__subtitle">{subtitle}</p>

                {this.state.filters && (
                    // TODO: Add active state to filter buttons
                    <div className="gallery__filters">
                        {this.state.filters.map((category, i) => (
                            <div key={i} className="gallery__filter-button">
                                <Button
                                    onClick={() => this.handleFilterChange(category)}
                                    disabled={category === currentFilter && category !== 'All'}
                                >
                                    {category}
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
                <div className="gallery__images">{this.renderImages()}</div>
                <div className="gallery__pagination">
                    <Pagination
                        totalItems={imageCount}
                        pageLimit={pageLimit}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                    />
                </div>

                <LightboxGallery
                    visible={lightboxVisible}
                    items={filteredImages}
                    startIndex={openImageIndex}
                    onClose={this.handleLightboxClose}
                />
            </div>
        );
    }
}

export default Gallery;
