import React, { ReactNode } from 'react';
import parse from 'html-react-parser';
import reactComponentSlots from '../../utils/reactComponentSlots';
import { Link } from '../../components';

import SvgChevronRight from '../../Icons/ChevronRight';
import SvgDownload from '../../Icons/Download';
import isExternal from '../../utils/isExternal';

interface ListItemProps {
    title: string;
    subtitle?: string;
    link?: string;
    href?: string;
    slot?: string;
    divider?: boolean;
    children?: ReactNode;
    external?: boolean;
    icon?: 'download';
    target?: string;
    thumb?: string;
}

const ListItem = (props: ListItemProps) => {
    const slots = reactComponentSlots(props);
    const isURLExternal = isExternal(props.href);

    const component = (
        <div className="list-item">
            {props.thumb || slots['media'] ? (
                <div className="list-item__media">
                    {props.thumb ? <img src={props.thumb} alt={props.title} /> : slots['media']}
                </div>
            ) : null}
            <div className="list-item__body">
                <h5 className="list-item__title">{props.title}</h5>
                {props.subtitle && <div className="list-item__subtitle">{parse(props.subtitle)}</div>}
            </div>
            {props.href ? (
                <div className="list-item__after">
                    {slots['after'] ? (
                        slots['after']
                    ) : (
                        <div className="list-item__icon">
                            {props.icon === 'download' ? (
                                <SvgDownload width={24} height={24} />
                            ) : (
                                <SvgChevronRight width={12} height={22} />
                            )}
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );

    if (props.href) {
        return (
            <Link
                href={props.href}
                title={props.title}
                target={isURLExternal ? props.target : '_self'}
                external={isURLExternal}
            >
                {component}
            </Link>
        );
    }

    return component;
};

export default ListItem;
