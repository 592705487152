import 'react-app-polyfill/ie11';

import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './sass/main.scss';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import objectFitImages from 'object-fit-images';
objectFitImages();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
