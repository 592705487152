import _ from 'lodash';
import React, { ReactNode } from 'react';
import reactComponentSlots from '../../utils/reactComponentSlots';

interface DashboardLayoutProps {
    children?: ReactNode;
    zoneCount: number;
}

const DashboardLayout = (props: DashboardLayoutProps) => {
    const slots = reactComponentSlots(props);

    return (
        <div className="dashboard">
            {_.times(props.zoneCount, i => {
                let num = i + 1;

                return (
                    <div key={i} className={`dashboard__zone dashboard__zone--${num}`}>
                        {slots[`zone${num}`]}
                    </div>
                );
            })}
        </div>
    );
};

DashboardLayout.defaultProps = {
    zoneCount: 4,
};

export default DashboardLayout;
