import React, { ReactNode } from 'react';

interface ImageProps {
    alt?: string;
    slot?: string;
    src: string;
    title?: string;
    height: string;
    width: string;
    children: ReactNode;
}

const Image = (props: ImageProps) => {
    const { children, ...rest } = props;
    return React.createElement('img', { className: 'image', ...rest });
};

export default Image;
