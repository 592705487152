import PERSIST_REHYDRATE from 'redux-persist/lib/constants';
import { UPDATE_DATE_MODIFIED } from '../actions/types';

export default (state = '', action) => {
    switch (action.type) {
        case PERSIST_REHYDRATE:
            return action.payload.dateModified || state;

        case UPDATE_DATE_MODIFIED:
            return action.payload;

        default:
            return state;
    }
};
