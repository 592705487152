const origin = window.location.origin;
const redirectUri = origin + '/callback';
const silentRedirectUri = origin + '/silentRenewCallback';

export default {
    authority: 'https://authentication-manager-unilever.azurewebsites.net/',
    client_id: 'playbook',
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: 'playbookUser',
    post_logout_redirect_uri: origin,
    silent_redirect_uri: silentRedirectUri,
    automaticSilentRenew: true,
};
