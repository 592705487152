import React, { ReactNode } from 'react';
import reactComponentSlots from '../../utils/reactComponentSlots';

interface NavbarProps {
    title?: string;
    children?: ReactNode;
}

const Navbar = (props: NavbarProps) => {
    const slots = reactComponentSlots(props);

    return (
        <div className="navbar">
            <div className="navbar__before">{slots['before']}</div>
            <div className="navbar__title">{slots['title'] ? slots['title'] : props.title}</div>
            <div className="navbar__after">{slots['after']}</div>
        </div>
    );
};

export default Navbar;
