import React, { Component } from 'react';
import { connect } from 'react-redux';

import parse from 'html-react-parser';

import { Link, Element as Section, animateScroll as scroll } from 'react-scroll';

import { overviewSelector } from './selectors/overview_selectors';

//import { playBookPostSelector, playbookCoreNavSelector } from './selectors/playbook_selectors';
import PageLayout from '../../layouts/PageLayout';

import { Title, TitleButton, Panel } from '../../components';

import Modal from 'react-modal';

// import buildComponents from '../../utils/buildComponents';
import { Grid, Box } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

interface OverviewProps {
    title: string;
    image: string;
    posts: any[];
}

class Overview extends Component<OverviewProps> {
    state = {
        navWidth: 0,
        navigation: [],
        isModalVisible: false,
        image: '',
    };

    nav = React.createRef<HTMLDivElement>();

    updateNavSize = () => {
        this.setState({ navWidth: this.nav.current.parentElement.clientWidth });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateNavSize);
        this.updateNavSize();

        this.setState({ navigation: this.props.posts.map(o => o.title) });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateNavSize);
    }

    handleModalOpen = image => {
        this.setState({ isModalVisible: true, image });
    };

    handleModalClose = () => {
        this.setState({ isModalVisible: false, image: '' });
    };

    render() {
        const { title, image, posts } = this.props;

        return (
            <>
                <PageLayout>
                    <div slot="nav" ref={this.nav}>
                        <ul
                            className="nav nav--fixed nav__descendant nav__descendant--active"
                            style={{ width: this.state.navWidth }}
                        >
                            {this.state.navigation.map((item, i) =>
                                i === 0 ? (
                                    <li className="nav__item nav__item--primary nav__item--active nav__item--parent">
                                        <a
                                            href="#"
                                            className="nav__link nav__link--active nav__link--parent"
                                            onClick={() => scroll.scrollToTop()}
                                        >
                                            {parse(title)}
                                        </a>
                                    </li>
                                ) : (
                                    <li key={i} className="nav__item nav__item--child">
                                        <Link
                                            //activeClass="nav__link nav__link--active"
                                            className="nav__link nav__link--child"
                                            to={`sections${i}`}
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            offset={-70}
                                        >
                                            {item}
                                        </Link>
                                    </li>
                                ),
                            )}
                        </ul>
                    </div>

                    <Title h1 theme="primary">
                        {parse(title)}
                    </Title>
                    <Box mb={1.5}>
                        <img
                            className="image"
                            src="http://unilever-playbook.dc-activ.com/wp-content/uploads/2019/07/test-modern-02.jpg"
                        />
                    </Box>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3}>
                                <TitleButton title="Modern" theme="theme-1" href="/playbook/modern/" />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TitleButton title="Discount" theme="theme-2" href="/playbook/discount/" />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TitleButton title="Beauty Stores" theme="theme-3" href="/playbook/beauty-store/" />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TitleButton title="Pharmacy" theme="theme-4" href="/playbook/pharmacy/" />
                            </Grid>
                        </Grid>
                    </div>

                    {posts.map((item, i) => (
                        <Box key={i} my={1.5}>
                            <Section name={`sections${i}`}>
                                <Panel theme="default" html={item.content}>
                                    {item.thumb && (
                                        <Box position="relative">
                                            <Box position="absolute" top="0" right="-20px">
                                                <button
                                                    className="expand-button"
                                                    onClick={() => this.handleModalOpen(item.thumb)}
                                                >
                                                    <ZoomInIcon />
                                                </button>
                                            </Box>
                                            <img src={item.thumb} width="100%" height="auto" />
                                        </Box>
                                    )}
                                </Panel>
                            </Section>
                        </Box>
                    ))}
                </PageLayout>
                <Modal
                    isOpen={this.state.isModalVisible}
                    contentLabel="Image"
                    className="content-modal"
                    overlayClassName="content-modal__overlay"
                >
                    <div className="content-modal__inner">
                        <div className="content-modal__content content-modal__content--large">
                            <button className="content-modal__close" onClick={this.handleModalClose}>
                                ×
                            </button>

                            <img src={this.state.image} style={{ width: '100%', height: 'auto' }} alt="" />
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    const { title, image, posts } = overviewSelector(state);

    return {
        title,
        image,
        posts,
    };
};

export default connect(mapStateToProps, null)(Overview);
