import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface NavTabsProps {
    slot?: string;
    children: ReactNode;
}

const NavTabs = (props: NavTabsProps) => {
    const { children } = props;

    const navTabsClass = classNames({
        [`nav-tabs`]: true,
    });

    return (
        <div className={navTabsClass}>
            {React.Children.toArray(children).map((child, i) => (
                <div className="nav-tabs__item" key={i}>
                    {child}
                </div>
            ))}
        </div>
    );
};

export default NavTabs;
