import _ from 'lodash';
import React, { Component } from 'react';
import Fuse from 'fuse.js';
import { Card, ButtonGroup, Checkbox, SearchBox } from '../../../components';

interface FiltersProps {
    data?: any[];
    categories: any[];
    filteredData?: any;
    slot?: any;
    hideSearch: boolean;
    hideCategories: boolean;
}

interface FiltersState {
    selectedFilters: string[];
    searchTerm: string;
}

const SEARCH_OPTIONS = {
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    findAllMatches: true,
    threshold: 0,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['title', 'excerpt', 'components.props.html'],
};

class Filters extends Component<FiltersProps, FiltersState> {
    static defaultProps = {
        data: [],
        categories: [],
        hideSearch: false,
        hideCategories: false,
    };

    state: Readonly<FiltersState> = {
        selectedFilters: [],
        searchTerm: '',
    };

    searchInput: any = React.createRef();

    onFilterChange = (category: string) => {
        this.setState(state => ({
            selectedFilters: _.xor(state.selectedFilters, [category]),
        }));
    };

    onSearchSubmit = (e: any) => {
        e.preventDefault();

        this.setState({ searchTerm: this.searchInput.value });
    };

    filterData() {
        const { selectedFilters, searchTerm } = this.state;
        let data = this.props.data;

        if (data) {
            if (searchTerm) {
                const fuse = new Fuse(data, SEARCH_OPTIONS);
                data = fuse.search(this.state.searchTerm);
            }

            if (_.size(selectedFilters)) {
                data = data.filter(item => {
                    let itemCategories = item.category.split(',');
                    let intersection = itemCategories.filter(function(n: string) {
                        return selectedFilters.indexOf(n) !== -1;
                    });
                    return intersection.length !== 0;
                });
            }

            this.props.filteredData(data);
        }
    }

    componentDidMount() {
        this.filterData();
    }

    componentDidUpdate(_: any, prevState: any) {
        if (this.state !== prevState) {
            this.filterData();
        }
    }

    render() {
        const { categories, hideSearch, hideCategories } = this.props;

        return (
            <>
                {!hideSearch && (
                    <Card title="Search">
                        <SearchBox
                            placeholder="Search"
                            onSubmit={this.onSearchSubmit}
                            ref={input => (this.searchInput = input)}
                        />
                    </Card>
                )}
                {!hideCategories && categories.length ? (
                    <Card title="Filters">
                        <ButtonGroup flow="row">
                            {categories &&
                                categories.map((category, i) => (
                                    <Checkbox
                                        key={i}
                                        label={category}
                                        onChange={() => this.onFilterChange(category)}
                                        checked={this.state.selectedFilters.includes(category)}
                                    />
                                ))}
                        </ButtonGroup>
                    </Card>
                ) : null}
            </>
        );
    }
}

export default Filters;
