import flattenChildren from './flatternChildren';

interface Props {
    children?: any;
}

interface Slots {
    [name: string]: string[];
}

export default function(props: Props) {
    const slots: Slots = {};

    if (!props) return slots;

    const children = flattenChildren(props.children);

    if (!children || Object.keys(children).length === 0) {
        return slots;
    }

    function addChildToSlot(name: string, child: any) {
        if (!slots[name]) slots[name] = [];
        slots[name].push(child);
    }

    if (Array.isArray(children)) {
        children.forEach(child => {
            if (!child) return;
            const slotName = (child.props && child.props.slot) || 'default';
            addChildToSlot(slotName, child);
        });
    } else {
        let slotName = 'default';
        if (children.props && children.props.slot) slotName = children.props.slot;
        addChildToSlot(slotName, children);
    }

    return slots;
}
