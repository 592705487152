import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    capabilityModulesSelector,
    capabilityModulesCategoriesSelector,
} from './selectors/capability_modules_selectors';
import PageLayout from '../../layouts/PageLayout';
import { Title, Panel, List, ListItem } from '../../components';
import Filters from '../@Shared/Filters/Filters';

// import parse from 'html-react-parser';

interface Props {
    title?: string;
    description?: string;
    posts: any[];
    categories: string[];
}

interface State {
    filteredData: any[];
    selected: any;
    isModalVisible: boolean;
}

class CapabilityModule extends Component<Props, State> {
    state: Readonly<State> = {
        filteredData: [],
        selected: [],
        isModalVisible: false,
    };

    handleFilteredData: any = (data: any) => {
        this.setState({ filteredData: data });
    };

    handleSelected: any = (id: any) => {
        const selected = this.state.filteredData.find(items => items.id === id);
        this.setState({ isModalVisible: true, selected });
    };

    render() {
        const { title, categories, posts } = this.props;

        return (
            <PageLayout>
                <Title slot="header" theme="primary">
                    Capability Modules and H&amp;B Summit Decks/Recordings
                </Title>

                <Filters slot="sidebar" data={posts} categories={categories} filteredData={this.handleFilteredData} />

                <Panel theme="default">
                    <List pageLimit={10} rowGap="small">
                        {this.state.filteredData.map(item => (
                            <ListItem
                                key={item.id}
                                title={item.title}
                                subtitle={item.excerpt}
                                href={item.path}
                                external
                                icon="download"
                                target="_blank"
                            >
                                {item.thumb && <img src={item.thumb} slot="media" alt={item.title} />}
                            </ListItem>
                        ))}
                    </List>
                </Panel>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { title, description, posts } = capabilityModulesSelector(state);
    const categories = capabilityModulesCategoriesSelector(state);

    return {
        title,
        description,
        posts,
        categories,
    };
};

export default connect(mapStateToProps, null)(CapabilityModule);
