import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsSelector, newsCategoriesSelector } from './selectors/news_selectors';
import PageLayout from '../../layouts/PageLayout';
import { Title, Panel, List, Button } from '../../components';
import Filters from '../@Shared/Filters/Filters';

import parse from 'html-react-parser';

interface Props {
    title?: string;
    description?: string;
    posts: any[];
    categories: any[];
}

interface State {
    filteredData: any[];
}

class News extends Component<Props, State> {
    state: Readonly<State> = {
        filteredData: [],
    };

    handleFilteredData: any = (data: any) => {
        this.setState({ filteredData: data });
    };

    render() {
        const { title, description, posts, categories } = this.props;

        return (
            <PageLayout>
                <Title slot="header">{title}</Title>
                {description && (
                    <Panel theme="default">
                        <h3>{description}</h3>
                    </Panel>
                )}
                <Filters slot="sidebar" data={posts} categories={categories} filteredData={this.handleFilteredData} />
                {/* <Panel theme="default"> */}
                <List pageLimit={4} rowGap="small" grid>
                    {this.state.filteredData.map(item => (
                        // <ListItem
                        //     key={item.id}
                        //     title={item.title}
                        //     subtitle={item.excerpt}
                        //     href={item.path}
                        //     external
                        //     icon="download"
                        // >
                        //     {item.thumb && <img src={item.thumb} slot="media" alt={item.title} />}
                        // </ListItem>
                        <Panel key={item.id} theme="default">
                            <div className="panel__body">
                                {item.thumb && <img src={item.thumb} alt={item.title} />}
                                <h5>{item.title}</h5>
                                <p>{parse(item.excerpt)}</p>
                                <Button href={item.path}>Read More</Button>
                            </div>
                        </Panel>
                    ))}
                </List>
                {/* </Panel> */}
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { title, description, posts } = newsSelector(state);
    const categories = newsCategoriesSelector(state);

    return {
        title,
        description,
        posts,
        categories,
    };
};

export default connect(mapStateToProps, null)(News);
