import _ from 'lodash';
import React, { Component } from 'react';
//import classNames from 'classnames';
import { Link } from '../';
interface BreadcrumbsProps {
    items: any[];
    activeId: number;
    slot?: string;
}

interface BreadcrumbsState {}

class Breadcrumbs extends Component<BreadcrumbsProps, BreadcrumbsState> {
    state = {
        index: {},
    };

    buildIndex(root: any, children: any, obj: any = {}) {
        children.forEach((child: any, i: any) => {
            obj[child.id] = {
                title: child.title,
                href: child.href,
                parent: root,
            };

            if (child.children) {
                this.buildIndex(child.id, child.children, obj);
            }
        });

        return obj;
    }

    getPath(index: any, id: any): any {
        if (_.isEmpty(index[id])) return [];

        let path = [];

        if (typeof index[id].parent !== undefined) {
            if (index[id].parent) {
                path = this.getPath(index, index[id].parent);
            }
        }

        return path.concat([index[id]]);
    }

    componentDidMount() {
        const index = this.buildIndex(false, this.props.items);
        this.setState({ index });
    }

    render() {
        if (!this.props.activeId) return null;
        const breadcrumbs = this.getPath(this.state.index, this.props.activeId);
        if (breadcrumbs.length < 2) return null;

        return (
            <nav className="breadcrumbs">
                <ul className="breadcrumbs__list">
                    {breadcrumbs.map((crumb: any, i: any) => (
                        <li key={i} className="breadcrumbs__item">
                            <Link href={crumb.href} className="breadcrumbs__link">
                                {crumb.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    }
}

export default Breadcrumbs;
