import React, { Component } from 'react';
import Modal from 'react-modal';
import SvgChevronRightCircle from '../../Icons/ChevronRightCircle';
import classNames from 'classnames';

import { Panel, Title } from '../';

const customStyles = {};

Modal.setAppElement('#root');

interface Content {
    title: string;
    thumb: string;
    image?: string;
    iframe?: string;
    html?: string;
}

interface Props {
    theme: string;
    contents: Content[];
}

interface State {
    showModal: boolean;
    currentModal: Content;
}

class ContentModal extends Component<Props, State> {
    state: Readonly<State> = {
        showModal: false,
        currentModal: this.props.contents[0],
    };

    openModal(e, index) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ showModal: true });

        this.handleContentChange(index);
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    handleContentChange(index) {
        const currentModal = this.props.contents.find((_, i) => {
            return i === index;
        });

        this.setState({ currentModal });
    }

    render() {
        const { theme, contents } = this.props;
        const { showModal, currentModal } = this.state;

        const contentClass = classNames({
            [`feature-button__content`]: true,
            [`feature-button__content--${theme}`]: theme,
        });

        return (
            <>
                <div className="content-modal-grid">
                    {contents.map((item, i) => (
                        <div key={i}>
                            <div className="feature-button" style={{ backgroundImage: `url(${item.thumb})` }}>
                                <a href="x" className="feature-button__main" onClick={e => this.openModal(e, i)}>
                                    <div className={contentClass}>
                                        <div className="feature-button__body">
                                            <h3 className="feature-button__title">{item.title}</h3>
                                        </div>
                                        <div className="feature-button__footer">
                                            <SvgChevronRightCircle
                                                width={36}
                                                height={36}
                                                color="white"
                                                className="feature-button__icon"
                                            />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>

                <Modal
                    isOpen={showModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="content-modal"
                    overlayClassName="content-modal__overlay"
                >
                    <div className="content-modal__inner">
                        <div className="content-modal__content">
                            <button className="content-modal__close" onClick={this.closeModal}>
                                ×
                            </button>
                            <Panel>
                                <Title h3 inverted>
                                    {currentModal.title}
                                </Title>
                                <img src={currentModal.image} className="image" alt="" />
                            </Panel>
                            <Panel html={currentModal.html} />
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ContentModal;
